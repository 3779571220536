import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import {
  setSelectedDepartment,
  setSelectedServicepoint,
} from '../../store/actions/DepartmentActions';
import smChevronDown from '../../pages/assets/sm_chevron-down.svg';
import { socketEventCallback } from '../../services/webSocket.service';
// import { callExternalApi } from '../../services/api-call';

const DashboardSideMenu = ({ setIsComponentVisible }: any) => {
  // const { logOut }: IAuthContext = React.useContext(AuthContext);
  const initialLoader = useSelector(
    (state: RootState) => state.initialLoaderReducer
  );
  const departmentReducer = useSelector(
    (state: RootState) => state.departmentReducer
  );
  const { profile, isSocketConnected, blockedStatus } = useSelector(
    (state: RootState) => state.userReducer
  );

  React.useEffect(() => {
    if (Object.keys(profile).length && isSocketConnected) {
      // @ts-ignore
      window.Pusher.subscribe(`private-users.${profile.id}`).bind_global(
        socketEventCallback
      );
    }
  }, [profile, isSocketConnected]);

  React.useEffect(() => {
    if (
      initialLoader.loaded &&
      departmentReducer.selectedServicepoint &&
      isSocketConnected
    ) {
      // @ts-ignore
      window.Pusher.subscribe(
        `private-partner-dashboard.${departmentReducer.selectedServicepoint.id}`
      ).bind_global(socketEventCallback);
    }
  }, [
    initialLoader,
    departmentReducer.selectedServicepoint,
    isSocketConnected,
  ]);

  const dispatch: any = useDispatch();
  // const navigate = useNavigate();
  //   const handleLogout = async() => {
  //     let token: any = localStorage.getItem('ROCP_token');

  // token = JSON.parse(token)
  //     const config = {
  //       url: `https://signin.slim-surface-app.com/oauth/logout?token=${token}&token_type_hint=refresh_token&client_id=${process.env.REACT_APP_CLIENT_ID}&post_logout_redirect_uri=${process.env.REACT_APP_URL}/login`,
  //       method: 'GET',
  //     };
  //     const { data, error } = await callExternalApi({ config });
  //     // console.log('error', error);
  //     if(!error){
  //       console.log("logged out successfully",data)
  //       // localStorage.clear()

  //     }
  //     else {
  //       console.log("logged out failed")
  //     }

  //   }
  return (
    <div>
      <ul>
        {!blockedStatus && (
          <>
            <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="/"
              >
                {' '}
                <FormattedMessage id="dashboard" />
              </Link>
            </li>
            {/* <li data-cy="invoices">
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="invoices"
              >
                {' '}
                <FormattedMessage id="invoices" />
              </Link>
            </li> */}
            <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="finance"
              >
                {' '}
                <FormattedMessage id="finance" />
              </Link>
            </li>
            {/* <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="payouts"
              >
                {' '}
                <FormattedMessage id="payouts" />
              </Link>
            </li> */}
            <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="services"
              >
                {' '}
                <FormattedMessage id="services" />
              </Link>
            </li>
            <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="orders"
              >
                <FormattedMessage id="orders" />
              </Link>
            </li>
            <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="employees"
              >
                <FormattedMessage id="employees" />
              </Link>
            </li>
            <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="subscriptions"
              >
                <FormattedMessage id="subscriptions" />
              </Link>
            </li>
            <li>
              <Link
                className="some-neutral_color"
                onClick={() => {
                  setIsComponentVisible(false);
                }}
                to="settings"
              >
                <FormattedMessage id="settings" />
              </Link>
            </li>
          </>
        )}
        <li>
          <Link
            className="some-neutral_color"
            onClick={async () => {
              // localStorage.setItem("logging_out","true")
              // await handleLogout()
              // const temp:any = localStorage.getItem("locale")
              // setIsComponentVisible(false);
              localStorage.clear();
              // localStorage.setItem("locale",temp)

              window.location.href = `${process.env.REACT_APP_LOGOUT_ENDPOINT}`;
            }}
            to=""
          >
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </Link>
        </li>
        {/* <li>
        <Link className="some-neutral_color" onClick={()=>{setIsComponentVisible(false)}} to="reviews">
          Reviews
        </Link>
      </li>
      <li>
        <Link className="some-neutral_color" onClick={()=>{setIsComponentVisible(false)}} to="services">
          Services
        </Link>
      </li>
      <li>
        <Link className="some-neutral_color" onClick={()=>{setIsComponentVisible(false)}} to="subscriptions">
          Subscriptions
        </Link>
      </li> */}
        <li className="d-block d-md-none">
          <div className="HeightSmall dropdown">
            <button
              className="btn btn-secondary"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {departmentReducer.selectedDepartment.name}{' '}
              <span
                className={
                  !departmentReducer.selectedDepartment.avatar &&
                  departmentReducer.departmentsList.length <= 1
                    ? 'd-none'
                    : ''
                }
              >
                <img src={smChevronDown} alt="" className="img-fluid" />
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {departmentReducer.departmentsList.map((dept: any) => {
                return (
                  <li
                    role="button"
                    key={dept.id}
                    onClick={() => {
                      dispatch(setSelectedDepartment(dept));
                    }}
                  >
                    <a
                      className={
                        departmentReducer.selectedDepartment.name === dept.name
                          ? 'dropdown-item StateDisabled'
                          : 'dropdown-item'
                      }
                      href="#"
                    >
                      {dept.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </li>
        <li className="d-block d-md-none">
          <div className="HeightSmall dropdown">
            <button
              className="btn btn-secondary"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {departmentReducer.selectedServicepoint?.name}
              <span
                className={
                  !departmentReducer.selectedServicepoint.avatar &&
                  departmentReducer.servicepointsList.length <= 1
                    ? 'd-none'
                    : ''
                }
              >
                <img src={smChevronDown} alt="" className="img-fluid" />
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {departmentReducer?.servicepointsList?.map((sp: any) => {
                return (
                  <li
                    key={sp.id}
                    role="button"
                    onClick={() => {
                      dispatch(setSelectedServicepoint(sp));
                    }}
                  >
                    <a
                      className={
                        sp.name === departmentReducer.selectedDepartment.name
                          ? 'dropdown-item StateDisabled'
                          : 'dropdown-item'
                      }
                      href="#"
                    >
                      {sp.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DashboardSideMenu;
