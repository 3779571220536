import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFilteredOrders, getOrders } from '../store/actions/OrdersAction';
import Paginator from '../components/Paginator/Paginator';
import '../components/Layout.css';
import DashboardOrders from '../components/Dashboard/DashboardOrders';
import smDownIcon from './assets/sm_chevron-down.svg';
import { RootState } from '../store';
import Searchbar from '../components/Searchbar';
import { SingleOrder } from '../Models/Orders.Model';
import axios from '../utils/Api';

const Orders = () => {
  const dispatch: any = useDispatch();
  const location = useLocation();
  const initialLoader = useSelector((state: any) => state.initialLoaderReducer);
  const [isOrdersPage, setIsOrdersPage] = React.useState(false);
  const [filteredOrders, setFilteredOrders] = React.useState<SingleOrder[]>([]);

  React.useEffect(() => {
    if (window.location.href.includes('orders')) setIsOrdersPage(true);
  }, []);
  React.useEffect(() => {
    if (initialLoader.loaded === true) {
      if (window.location.href.includes('statuses[]')) {
        let y = location.search.replace('?', '');
        y = y.replace(/&/g, '');
        const L = y.split('statuses[]=');
        L.splice(0, 1);
        // console.log("y",L)
        // const x = location.search.split("&statuses[]=")
        // x.splice(0,1)
        // console.log("x",x)
        //
        dispatch(getFilteredOrders(1, L));
        const tempOrderStatusArr: any = [];
        const tempOrderURL: any = [];
        L.map((status: string) => {
          tempOrderStatusArr.push(status);
          return tempOrderURL.push(status);
        });
        setOrderStatusCondition(tempOrderStatusArr);
        setOrderUrl(tempOrderURL);
      } else dispatch(getOrders());
    }

    return () => {
      setOrderStatusCondition([]);
      document
        .querySelectorAll('input[type=checkbox]')
        .forEach((el: any) => (el.checked = false));
    };
  }, [initialLoader]);
  const [orderStatusCondition, setOrderStatusCondition] = React.useState<
    string[]
  >([]);
  const [orderUrl, setOrderUrl] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const ordersData = useSelector((state: RootState) => state.orderReducer);
  const appLoader = useSelector((state: any) => state.appSpinnerReducer);
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  // window.onload = function () {
  //   console.log('X2', orderStatusCondition);
  // };
  React.useEffect(() => {
    const input = document.getElementsByTagName('input');
    if (input)
      for (let i = 0; i < input.length; i++) {
        // @ts-ignore
        if (orderUrl.includes(input[i].value)) input[i].checked = true;
      }
  }, [orderUrl]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (!event.target.value.trim()) {
      setFilteredOrders([]);
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    console.log(`Searching for: ${searchTerm}`);
    axios
      .get(`/orders?query=${searchTerm}`)
      .then((response: any) => {
        console.log('After Search Orders Found ', response.data.data);
        const limitedResults = response.data.data;
        setFilteredOrders(limitedResults);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCheckbox = (e: any) => {
    const input = document.querySelectorAll(`[id=${e.target.id}]`);
    if (input)
      for (let i = 0; i < input.length; i++) {
        // @ts-ignore
        input[i].checked = e.target.checked;
        // console.log("inputs",input.checked)
      }
    if (e.target.checked) {
      setOrderStatusCondition([...orderStatusCondition, e.target.value]);
      dispatch(getFilteredOrders(1, [...orderStatusCondition, e.target.value]));
      if (orderStatusCondition[0] !== '') {
        const temp = orderStatusCondition;
        temp.push(e.target.value);
        let statuses = '';
        for (let i = 0; i < temp.length; i++) {
          if (i < temp.length - 1)
            statuses = `${statuses}statuses[]=${temp[i]}&`;
          else statuses = `${statuses}statuses[]=${temp[i]}`;
        }
        window.history.pushState(
          'page',
          'Title',
          `${process.env.REACT_APP_URL}/orders?&${statuses}`
        );
      }
    } else {
      if (window.location.href.includes(e.target.value))
        window.history.pushState(
          'page',
          'Title',
          `${process.env.REACT_APP_URL}/orders`
        );
      let statuses = '';
      const temp = orderStatusCondition;
      const index = orderStatusCondition.indexOf(e.target.value);
      if (index !== -1) {
        temp.splice(index, 1);
        setOrderStatusCondition(temp);
        for (let i = 0; i < temp.length; i++) {
          if (i < temp.length - 1)
            statuses = `${statuses}statuses[]=${temp[i]}&`;
          else statuses = `${statuses}statuses[]=${temp[i]}`;
        }
        if (temp.length === 0)
          window.history.pushState(
            'page',
            'Title',
            `${process.env.REACT_APP_URL}/orders`
          );
        else
          window.history.pushState(
            'page',
            'Title',
            `${process.env.REACT_APP_URL}/orders?&${statuses}`
          );
      }
      dispatch(getFilteredOrders(1, temp));
    }
  };

  return (
    <div>
      <div className="components-card">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="heading-media order-media"
                style={{ marginBottom: '20px' }}
              >
                <div className="heading mb-0">
                  <h2>
                    <FormattedMessage id="orders" />
                  </h2>
                </div>
                <div className="heading-right">
                  <div className="media d-block d-md-none">
                    <div className="HeightSmall dropdown">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FormattedMessage id="allOrders" />
                        {!ordersData.meta.total
                          ? ' (0)'
                          : ` (${ordersData.meta.total})`}
                        <span>
                          <img src={smDownIcon} alt="" className="img-fluid" />
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                        style={{ minWidth: '16rem' }}
                      >
                        {Object.keys(ordersData.meta).length !== 0 &&
                          Object.keys(ordersData?.meta?.statuses).map((key) => {
                            return (
                              <li key={key}>
                                <div
                                  className="PositionStart"
                                  style={{ padding: '8px 8px' }}
                                >
                                  <label
                                    className="dropdown-item"
                                    htmlFor={key}
                                    style={{ display: 'flex', gap: '3%' }}
                                    // onChange={handleCheckbox}
                                  >
                                    <input
                                      onChange={handleCheckbox}
                                      className="StateChecked "
                                      type="checkbox"
                                      value={key}
                                      id={key}
                                      defaultChecked={orderUrl.includes(key)}
                                      disabled={!appLoader.loaded.orders}
                                      // style={{ marginRight: '3%' }}
                                    />
                                    <span>
                                      <FormattedMessage id={key} />{' '}
                                    </span>
                                    <span
                                      className="CounterYes"
                                      style={{ marginLeft: '3%' }}
                                    >
                                      {ordersData.meta.statuses[`${key}`]}
                                    </span>
                                  </label>
                                </div>
                              </li>
                              //   <li>
                              //   <a className="dropdown-item" href="/#">
                              //     Option
                              //   </a>
                              // </li>
                            );
                          })}
                        {/* <li>
                          <a className="dropdown-item StateDisabled " href="/#">
                            Option
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  <ol className="d-none d-md-flex">
                    {Object.keys(ordersData.meta).length !== 0 &&
                      Object.keys(ordersData?.meta?.statuses).map(
                        (key, idx) => {
                          return (
                            <li key={key}>
                              <div
                                className={
                                  idx === 0
                                    ? 'PositionStart'
                                    : idx ===
                                      Object.keys(ordersData?.meta?.statuses)
                                        .length -
                                        1
                                    ? 'PositionEnd'
                                    : 'PositionMiddle'
                                }
                              >
                                <label
                                  htmlFor={key}
                                  className="Has-items"
                                  style={{ marginLeft: 0 }}
                                >
                                  <input
                                    onChange={handleCheckbox}
                                    className="StateChecked "
                                    type="checkbox"
                                    value={key}
                                    id={key}
                                    defaultChecked={orderUrl.includes(key)}
                                    disabled={!appLoader.loaded.orders}
                                    style={{ marginRight: '5px' }}
                                  />
                                  <FormattedMessage id={key} />{' '}
                                  <span className="CounterYes">
                                    {ordersData.meta.statuses[`${key}`]}
                                  </span>
                                </label>
                              </div>
                            </li>
                          );
                        }
                      )}
                  </ol>
                </div>
              </div>
              {isOrdersPage && (
                <div className="d-flex flex-row justify-content-end">
                  <div className="pb-2">
                    <Searchbar
                      search={searchTerm}
                      handleInputChange={handleInputChange}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </div>
              )}

              {filteredOrders.length !== 0 ? (
                loading ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ width: '100%' }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <DashboardOrders orders={filteredOrders} />
                )
              ) : (
                <>
                  <DashboardOrders orders={ordersData?.ordersList} />
                  <div className="sorting-table">
                    <p className="sorting">
                      <FormattedMessage
                        id="resultsPerView"
                        defaultMessage="Results per view"
                      />
                      : <strong>&nbsp;5</strong>
                    </p>
                    <Paginator
                      meta={ordersData.meta}
                      dataLength={ordersData.ordersList.length}
                      getPageData={getFilteredOrders}
                      param={orderStatusCondition}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
