import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import globeIcon from '../../pages/assets/sm_globe.svg';
import pinIcon from '../../pages/assets/sm_map-pin.svg';
import phoneIcon from '../../pages/assets/sm_phone.svg';
import Tasks from '../../pages/Tasks';
import ModalBs from '../subComponents/ModalBs';
import chevronRight from '../../pages/assets/sm_arrow-right.svg';
import smAt from '../../pages/assets/sm_at.svg';

const DashboardSideBar = ({ departmentReducer }: any) => {
  const [progress, setProgress] = React.useState(0);
  // const [showProfileModal, setShowProfileModal] = React.useState(false);
  const invoiceData = useSelector((state: any) => state.invoiceReducer);
  const ordersData = useSelector((state: any) => state.orderReducer);

  React.useEffect(() => {
    let compTask = 0;
    const totalTask = 2;
    if (invoiceData.meta.payable_invoices?.total === 0) {
      // console.log("scz",invoiceData.meta.payable_invoices.total,ordersData.meta?.statuses?.awaiting_completion)
      compTask++;
    }

    if (ordersData.meta?.statuses?.awaiting_completion === 0) {
      compTask++;
    }

    const temp = (compTask / totalTask) * 100;

    setProgress(temp);
  }, [invoiceData, ordersData]);
  // const showPModal = () => {
  //   setShowProfileModal(true);
  // };
  // const closePModal = () => {
  //   setShowProfileModal(false);
  // };
  return (
    <div className="dashboard-left">
      {/* <ModalBs
        show={showProfileModal}
        handleClose={closePModal}
        title="profile"
        text="blank"
        okButtonText="Close"
        okButtonAction={closePModal}
        cancelButtonText="cancel"
        cancelButtonAction={closePModal}
        // inputAction={setEmail}
        inputDefaultValue=""
        showCancelButton={false}
        showAlert
        alertText="profileModalText"
        alertDynamo={{
          companyEmail: 'partnersupport@serviceright.nl',
          code: (word: string) => (
            <a href="mailto:partnersupport@serviceright.nl">{word}</a>
          ),
        }}
      /> */}
      <div className="dashboard-box">
        <div className="heading">
          <h2>
            <FormattedMessage
              defaultMessage="Your company information"
              id="company-information-header"
            />
          </h2>
        </div>
        {departmentReducer?.selectedServicepoint?.avatar && (
          <div className="image-holder">
            <img
              src={departmentReducer.selectedServicepoint?.avatar}
              alt=""
              className="img-fluid"
            />
          </div>
        )}
        <div className="card" style={{ padding: '10px 0px' }}>
          {/* adding padding 0 because of removal of side image */}
          <div className="card-box" style={{ padding: 0 }}>
            <ul>
              <li>
                <p className="some-neutral_color">
                  <span>
                    <img src={pinIcon} alt="" className="img-fluid" />
                  </span>
                  <strong>
                    {departmentReducer.selectedServicepoint?.street}{' '}
                    {departmentReducer?.selectedServicepoint?.street_number}
                    ,&nbsp;
                    {departmentReducer.selectedServicepoint?.zip_code}{' '}
                    {departmentReducer.selectedServicepoint?.city}
                    ,&nbsp;
                    <FormattedMessage
                      id="full-country-name"
                      defaultMessage="Netherlands"
                    />
                  </strong>
                </p>
              </li>
              <li>
                <p className="some-neutral_color">
                  <span>
                    <img src={phoneIcon} alt="" className="img-fluid" />
                  </span>
                  {departmentReducer.selectedServicepoint?.phone ? (
                    <strong>
                      {' '}
                      {departmentReducer.selectedServicepoint?.phone}{' '}
                    </strong>
                  ) : (
                    <strong>Onbekend</strong>
                  )}
                </p>
              </li>
              <li>
                <p>
                  <Link to="/#" className="some-neutral_color">
                    <span>
                      <img alt="" src={globeIcon} className="img-fluid" />
                    </span>{' '}
                    {departmentReducer.selectedServicepoint?.website ? (
                      <strong>
                        {' '}
                        {departmentReducer.selectedServicepoint.website}
                      </strong>
                    ) : (
                      <strong>Onbekend</strong>
                    )}
                  </Link>
                </p>
              </li>
              <li>
                <div>
                  <p className="some-neutral_color">
                    <span>
                      <img src={smAt} alt="" className="img-fluid" />
                    </span>{' '}
                    {departmentReducer.selectedServicepoint?.email ? (
                      <strong>
                        {' '}
                        {departmentReducer.selectedServicepoint.email}
                      </strong>
                    ) : (
                      <strong>Onbekend</strong>
                    )}
                  </p>

                  <Link
                    to={
                      departmentReducer?.selectedServicepoint?.public_url
                        ? {
                            pathname:
                              departmentReducer.selectedServicepoint.public_url,
                          }
                        : '#'
                    }
                    target={
                      departmentReducer.selectedServicepoint.public_url
                        ? '_blank'
                        : ''
                    }
                    className="some-neutral_color"
                  >
                    <span>
                      {/* <img
                  src={smAt}
                  alt=""
                  className="img-fluid"
                /> */}
                    </span>{' '}
                    {departmentReducer.selectedServicepoint?.public_url ? (
                      <strong>
                        {' '}
                        <a
                          href={
                            departmentReducer.selectedServicepoint.public_url
                              ? departmentReducer.selectedServicepoint
                                  .public_url
                              : '#'
                          }
                          target={
                            departmentReducer.selectedServicepoint.public_url
                              ? '_blank'
                              : ''
                          }
                          rel="noreferrer"
                        >
                          {departmentReducer.selectedServicepoint.public_url}
                        </a>
                      </strong>
                    ) : (
                      <strong>Onbekend</strong>
                    )}
                  </Link>
                </div>
              </li>
              <li className="text-end">
                <Link to="settings" className="Some-link">
                  <FormattedMessage id="profile" />{' '}
                  <img src={chevronRight} alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dashboard-box">
        <div className="heading">
          <h2>
            <FormattedMessage id="finishYourSteps" />
          </h2>
        </div>
        <div className="data-type-progress progress-height mt-3 mb-3">
          <div className="progress">
            <div className="progress-bar" style={{ width: `${progress}%` }}>
              {progress}%
            </div>
          </div>
        </div>
        {/* <div className="TypeCollapsed">
        <Link to="/#">
          <div className="Has-items m-0">
            <p className="Neutral-High-contrast"> Show all items </p>{' '}
            <span className="CounterYes">7</span>
          </div>
          <span className="Collapsed-btn">
            <img
              src="images/sm_chevron-right.svg"
              alt=""
              className="img-fluid"
            />{' '}
          </span>
        </Link>
      </div> */}
      </div>
      <Tasks />
    </div>
  );
};

export default DashboardSideBar;
