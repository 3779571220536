import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'; // @ts-ignore
// import FreshChat from 'react-freshchat'
import Dashboard from './pages/Dashboard';
import Employees from './pages/Employees';
import Reviews from './pages/Reviews';
import Settings from './pages/settings';
import Subscriptions from './pages/Subscriptions';
import Profile from './pages/Profile';
import Invoices from './pages/Invoices';
import NavBar from './components/NavBar';
import Orders from './pages/Orders';
import Services from './pages/Services';
import Tasks from './pages/Tasks';
import OrderDetail from './pages/OrderDetail';
import Payouts from './pages/Payouts';
import PublicPayoutPage from './pages/PublicPayoutPage';
import Loader from './components/subComponents/Loader';
import Auth from './components/subComponents/Auth';
import LoggedOutNavbar from './components/LoggedOutNavbar';
import Login from './components/subComponents/Login';
import MonthlyPerformance from './pages/MonthlyPerformance';
import Breadcrumbs from './components/subComponents/Breadcrumbs';
import MagicLink from './pages/MagicLink';
import Finance from './pages/Finance';
import AccessDenied from './pages/AccessDenied';
import StripeConnectOnboarding from "./pages/StripeConnectOnboarding";
import Onboarding from "./components/Onboarding";
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export const PublicRoute = () => {
  return (
    <Routes>
      <Route path="/invoices/payall" element={<PublicPayoutPage />}>
        <Route path="cancel" element={<PublicPayoutPage />} />
        <Route path="success" element={<PublicPayoutPage />} />
        <Route path=":id" element={<PublicPayoutPage />} />
      </Route>
    </Routes>
  );
};

export const MagicRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<MagicLink />} />
    </Routes>
  );
};
const UnBlockedRoutes = () => {
  const { token }: IAuthContext = React.useContext(AuthContext)
  const onboardingReducer = useSelector((state: any) => state.onboardingReducer)
  // console.log("logged in",token)

  return (
    <Routes>
      {onboardingReducer.isOnboardingRequired ? <Route path="*" element={<Onboarding/>} /> : token ? (
        <>
          <Route path="/" element={<Dashboard />} />

          <Route path="/employees" element={<Employees />} />
          <Route path="/monthlyPerformance" element={<MonthlyPerformance />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/oauth/callback" element={<Auth />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/payouts" element={<Navigate to="/finance" replace />} />
          {/* <Route path="/payouts" element={<Payouts />} /> */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/services" element={<Services />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/access-denied" element={<AccessDenied/>} />
          <Route
            path="/orders?statuses[]=awaiting_completion"
            element={<Orders />}
          />
          <Route path="/orders?statuses[]=cancelled" element={<Orders />} />
          <Route path="/orders?statuses[]=completed" element={<Orders />} />
          <Route path="/orders?statuses[]=processing" element={<Orders />} />
          <Route path="/orders?statuses[]=scheduled" element={<Orders />} />

          <Route path="/orders/:id" element={<OrderDetail />} />

          {/* <Route path="/invoices" element={<Invoices />} /> */}
          <Route path="/finance" element={<Finance />} />
          <Route
            path="/invoices"
            element={<Navigate to="/finance" replace />}
          />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/finance/success" element={<Finance />} />
          <Route path="/finance/cancel" element={<Finance />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      ) : (
        <>
          {' '}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Loader />} />
          <Route path="/oauth/callback" element={<Auth />} />
          {/* <
          Route path="*" element={<Navigate to="/" replace />} /> */}
        </>
      )}
    </Routes>
  );
};
const BlockedRoutes = () => {
  return (
    <Routes>
      <Route path="/invoices" element={<Invoices />} />
      <Route path="*" element={<Navigate to="/invoices" replace />} />
    </Routes>
  );
};

const AppRouter = () => {
  const { token }: IAuthContext = React.useContext(AuthContext);
  const userReducer = useSelector((state: any) => state.userReducer);
  if (token === '' && !localStorage.getItem('origin')) {
    localStorage.setItem('origin', window.location.href);
  }
  return (
    <Router>
      {token ? <NavBar /> : <LoggedOutNavbar />}
      <Breadcrumbs />
      {/* <TawkMessengerReact
                propertyId="property_id"
                widgetId="default"/> */}
      {/* {Object.keys(userReducer.profile).length !== 0 && (
        <FreshChat
          token={process.env.REACT_APP_FRESH_CHAT_TOKEN}
          email={userReducer.profile.email}
          host={process.env.REACT_APP_FRESH_CHAT_HOST}
          widgetUuid={process.env.REACT_APP_FRESH_CHAT_WIDGET_UUID}
          onInit={(widget: any) => {
            widget.user.setProperties({
              first_name: userReducer.profile.first_name,
              locale: userReducer.locale.locale,
            });
          }}
        />
      )} */}

      {userReducer.blockedStatus ? <BlockedRoutes /> : <UnBlockedRoutes />}
    </Router>
  );
};

export default AppRouter;
