// @ts-nocheck
import axios from '../../utils/Api';
import { doAPICallWithCallBack } from '../../utils/helper';
import { SET_ALL_EMPLOYEES_DATA } from '../constants';
import { setEmoloyeesLoader } from './AppSpinnerActions';

export const getEmployees =
  (page = 1, limit = 5) =>
  (dispatch) => {
    dispatch(setEmoloyeesLoader(false));
    axios
      .get(`/employees?limit=${limit}&page=${page}`)
      .then((response: any) => {
        dispatch({
          type: SET_ALL_EMPLOYEES_DATA,
          payload: response,
        });
        dispatch(setEmoloyeesLoader(true));
      })
      .catch((error) => {
        console.log('err', error);
        dispatch(setEmoloyeesLoader(true));
      });
  };

export const deleteEmployee = (id, callBack) => () => {
  return doAPICallWithCallBack(`/employees/${id}`, callBack, 'delete');
};

export const updateEmployeeNotification = (id, callBack, status) => () => {
  return doAPICallWithCallBack(
    `/employees/${id}/notifications`,
    callBack,
    'patch',
    status
  );
};

export const createEmployee = (data: any, callBack) => () => {
  return doAPICallWithCallBack('/employees', callBack, 'post', data);
};
