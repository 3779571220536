import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import {withRouter} from '../../customHooks/withRouter';

const Breadcrumbs = (props:any) => {
  const { router } = props;
  const { location } = router;
  const pathnames = location.pathname.split('/').filter((x:any) => x);

  const onboardingReducer = useSelector((state: any) => state.onboardingReducer)
  // console.log(pathnames,location.pathname.includes("oauth"),location.pathname);
  //    if(pathname.pathname)pathnames=pathname?.pathname?.split("/").filter(x => x);
  return (
   <div>
     {(location.pathname !== '/' && location.pathname !== '/oauth/callback')&&
    
    <nav
      aria-label="breadcrumb "
      className="container d-flex fellx-row justify"
      style={{ marginTop: '100px', marginBottom:'-100px'}}
    >
      <ol className="breadcrumb ">
        <li className="breadcrumb-item">
          <Link to={onboardingReducer.isOnboardingRequired ? '#' : '/'} aria-current="page">
            <FormattedMessage id="dashboard"/>
          </Link>
        </li>
        {pathnames.map((name:any, index:number) => {
          let routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          if(onboardingReducer.isOnboardingRequired) {
            routeTo = '#';
          }
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <li key={name} className="breadcrumb-item active text text-capitalize ">
              {' '}
              <FormattedMessage id={name}/>
            </li>
          ) : (
            <li
              key={name}
              className="breadcrumb-item text-capitalize"
              // onClick={() => {
              //   console.log(routeTo);
              //   navigate(routeTo);
              // }}
            >

              <Link to={routeTo}><FormattedMessage id={name}/></Link>
            </li>
          );
        })}
      </ol>
    </nav>
    } 
   </div>
  );
};
export default withRouter(Breadcrumbs);
