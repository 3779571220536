import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const navigate = useNavigate();
  const { token }: IAuthContext = React.useContext(AuthContext);

  React.useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 1200);
  }, [token]);
  return (
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: '85vh' }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="loaderText">
          {' '}
          <FormattedMessage id="loading" defaultMessage="Loading..." />
        </p>
      </div>
    </div>
  );
};

export default Auth;
