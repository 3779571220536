/* eslint-disable */
import axios from 'axios';
import { redirect } from 'react-router-dom';
import { store } from '../store'
import { setSelectedDepartment, setSelectedServicepoint } from '../store/actions/DepartmentActions';

const department = localStorage.getItem('department');
let locale: any | null = localStorage.getItem('locale');
const servicepoint = localStorage.getItem('servicepoint');
let token: any = localStorage.getItem('ROCP_token');
if (locale != null) {
    locale = JSON.parse(locale);
}
token = JSON.parse(token)
const api = axios.create({
    baseURL: process.env.REACT_APP_PARTNER_API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        ...department ? ({ 'X-Department': department }) : {},
        ...servicepoint ? ({ 'on-behalf-of': servicepoint }) : {},
        'X-Country': locale ? locale['ISO'] : 'NL',
        'Accept-Language': localStorage.getItem('locale') ?JSON.parse(localStorage.getItem('locale')!)?.locale: 'nl'
    }
});
 

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const { dispatch }: any = store;
        
    if (error.response && 401 === error.response.status) {
        localStorage.clear();
      return  window.location.href=`${process.env.REACT_APP_LOGOUT_ENDPOINT}`
    }
    return Promise.reject(error);
});

function setDepartmentKey(departmentData: any) {
    const departmentKey = departmentData.slug;
    const { dispatch }: any = store;
    dispatch(setSelectedDepartment({ departmentData }));
    return departmentKey;
}
function setServicepointIdName(servicepointData: any) {
    const servicepointId = servicepointData.id;
    const { dispatch }: any = store;
    dispatch(setSelectedServicepoint(servicepointData));
    return servicepointId;
}

api.interceptors.request.use((config: any) => {
    const getConfig = config;
    const { departmentReducer } = store.getState();

    const { departmentsList, servicepointsList } = departmentReducer;
    let departmentKey;
    let servicepointId;
    const departmentFromStorage = localStorage.getItem('department') || null;
    const servicepointFromStorage = localStorage.getItem('servicepoint') || null;
    if (!localStorage.getItem('ROCP_token')) {
        localStorage.clear();
        return window.location.href=`${process.env.REACT_APP_LOGOUT_ENDPOINT}`
      }
    if (getConfig.url === '/departments') {
        getConfig.headers['X-Department'] = null;
        getConfig.headers['on-behalf-of'] = null;
        return getConfig;
    }
    if (departmentsList.length === 0) {
        return getConfig;
    }

    if (departmentFromStorage) {
        const filteredDepartment = departmentsList.find((element: any) => element.slug === departmentFromStorage);
        departmentKey = filteredDepartment ? filteredDepartment.slug : setDepartmentKey(departmentsList[0]);
        getConfig.headers['X-Department'] = departmentKey;
    } else {
        getConfig.headers['X-Department'] = setDepartmentKey(departmentsList[0]);
    }

    if (servicepointsList.length === 0) {
        return getConfig;
    }

    if (servicepointFromStorage) {
        const filteredServicepoint = servicepointsList.find((element: any) => element.id === servicepointFromStorage);
        servicepointId = filteredServicepoint ? filteredServicepoint.id : setServicepointIdName(servicepointsList[0]);
        getConfig.headers['on-behalf-of'] = servicepointId;
    } else {
        getConfig.headers['on-behalf-of'] = setServicepointIdName(servicepointsList[0]);
    }
    // console.log("config before",config.headers['Accept-Language'])
    // console.log("lco",JSON.parse(localStorage.getItem('locale')!)?.locale)
  
     if(config.headers['Accept-Language']!==JSON.parse(localStorage.getItem('locale')!)?.locale){
        getConfig.headers['Accept-Language']=JSON.parse(localStorage.getItem('locale')!)?.locale
     }
    // console.log("config before",config.headers['Accept-Language'])

    return getConfig;
}, (error) => {
    console.log("errorerror-=", error)
    return Promise.reject(error);
});

export default api;
