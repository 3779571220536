import { SET_SERVICES_DATA } from "../constants";


const initialState = {
    servicesList: [], 
};

export default (
    state: any = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case SET_SERVICES_DATA:
            return { servicesList:payload.data.data };
        default:
            return state;
    }
};
