import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const MagicLink = () => {
  // console.log("in magic link",window.location,location.search)
  React.useEffect(() => {
    localStorage.clear();
    localStorage.setItem('origin', window.location.pathname);
    const magicLink = window.location.search.split('?login-signature=');
    localStorage.setItem('magicLink', magicLink[1]);
    localStorage.setItem('magicLogin', 'true');
    window.location.replace(window.location.origin);
    // console.log("x",x)
  });
  return (
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: '85vh' }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="loaderText">
          {' '}
          <FormattedMessage id="loading" defaultMessage="Loading..." />
        </p>
      </div>
    </div>
  );
};

export default MagicLink;
