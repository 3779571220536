import axios from "../../utils/Api";
import { doAPICallWithCallBack } from "../../utils/helper";
import { setPayoutsLoader } from "./AppSpinnerActions";

export const loadPayouts = (page=1) => (dispatch:any) =>{
    dispatch(setPayoutsLoader(false))
    axios.get(`/invoices/upcoming-payouts?limit=25&page=${page}`)
    .then((resp)=>{
        dispatch({type:"GET_PAYOUTS",payload:resp})
        dispatch(setPayoutsLoader(true))
    })
    .catch((err)=>{
        console.log("err",err)
        dispatch(setPayoutsLoader(true))
    })
}

export const exportPayouts = (data:any,callBack:any) => () => {
    return doAPICallWithCallBack("/payouts/exports", callBack, 'post',data)
  }