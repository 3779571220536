import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { App } from './App';
import { store } from './store';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'font-awesome/css/font-awesome.min.css';

// React Google Tag manager
const tagManagerArgs = {
  gtmId: 'GTM-000000',
};

if (process.env.REACT_APP_GOOGLE_TAGMANAGER_CONTAINER_ID !== undefined) {
  tagManagerArgs.gtmId = process.env.REACT_APP_GOOGLE_TAGMANAGER_CONTAINER_ID;
}

TagManager.initialize(tagManagerArgs);
// ----------------------------------

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
