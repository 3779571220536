import React from 'react';
import { FormattedMessage } from 'react-intl';
import { statusCards } from '../../utils/constants/statusCards';

const StatusTag = ({ status }: string | any) => {
  const styleClass = statusCards.find((cur) => cur.id === status);
  return (
    <span
      className={styleClass?.class}
      // style={{ textTransform: 'capitalize' }}
    >
      <FormattedMessage id={status} defaultMessage={status} />
    </span>
  );
};

export default StatusTag;
