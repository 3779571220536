import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import uploadImg from '../../pages/assets/sm_upload.svg';

const Dropzone = ({ handleDrop }: any) => {
  const [fileAdded, setFileAdded] = React.useState(false);
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    setFileAdded(true);
    // console.log('filedropeed', acceptedFiles);
    handleDrop(acceptedFiles);
  }, []);
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({ onDrop });

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {};

  const acceptStyle = {
    padding: '53px 0px',
  };
  const rejectStyle = {
    padding: '53px 0px',
  };

  const style: any = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <div>
      {!fileAdded ? (
        <div className="dropping-box">
          <div className="dropping-area">
            <div {...getRootProps({ style })}>
              <input className="input-zone" {...getInputProps()} />
              <div className="text-center">
                <span>
                  {' '}
                  <FormattedMessage id="dropFilesHere" />{' '}
                </span>{' '}
                <button type="button" className="box-btn">
                  <img src={uploadImg} className="img-fluid" alt="" />
                  <FormattedMessage id="settings.upload" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="dropping-box dragging-box">
          <div className="dropping-area dropAreaPadder">
            {!fileAdded && (
              <span>
                <FormattedMessage id="dropFilesHere" />
              </span>
            )}
            <p style={{ margin: '15px 0 0 0' }}>
              <img
                src={uploadImg}
                style={{ width: '39px' }}
                className="img-fluid"
                alt=""
              />
            </p>

            <aside style={{ marginBottom: '11px' }}>
              <ul>{files}</ul>
            </aside>

            <button type="button" className="box-btn" onClick={open}>
              <img src={uploadImg} className="img-fluid" alt="" />
              <FormattedMessage id="replace" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Dropzone;
