import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DashboardComponentContainer from '../components/Dashboard/DashboardComponentContainer';
import DashboardInvoices from '../components/Dashboard/DashboardInvoices';
import DashboardOrders from '../components/Dashboard/DashboardOrders';
import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import '../components/Layout.css';
import { RootState } from '../store';
import { getInvoices } from '../store/actions/InvoicesActions';
// import { getMonthlyPerformance } from '../store/actions/MonthlyPerformanceActions';
import { getOrders } from '../store/actions/OrdersAction';
import MonthlyPerformance from './MonthlyPerformance';
import chevronRight from './assets/sm_arrow-right.svg';
import AppSpinner from '../components/subComponents/AppSpinner';
import usePayAll from '../customHooks/usePayAll';
import SubscriptionRequiresPaymentPopup from '../components/SubscriptionRequiresPaymentPopup';

const Dashboard = () => {
  const dispatch: any = useDispatch();

  const initialLoader = useSelector(
    (state: RootState) => state.initialLoaderReducer
  );
  const ordersData = useSelector((state: RootState) => state.orderReducer);
  const blockedStatus = useSelector(
    (state: RootState) => state.userReducer.blockedStatus
  );

  const requiresSubscriptionPayment = useSelector(
    (state: RootState) => state.userReducer.subscriptionRequiresPayment
  );

  const unpaidInvoices = useSelector((state: RootState) => {
    const meta = state.invoiceReducer?.meta || {};
    return (
      meta.late?.total > 0 ||
      false ||
      meta.reminded?.total > 0 ||
      false ||
      meta.uncollectible?.total > 0 ||
      false
    );
  });

  const { useAllPayment } = usePayAll();
  React.useEffect(() => {
    if (initialLoader.loaded === true && !blockedStatus) {
      dispatch(getInvoices(1, 5));
      dispatch(getOrders());
      // debugger
      // dispatch(getMonthlyPerformance());
    }
  }, [initialLoader]);

  const invoiceData = useSelector((state: RootState) => state.invoiceReducer);

  const appLoader = useSelector((state: any) => state.appSpinnerReducer);
  return requiresSubscriptionPayment === false ? (
    <DashboardWrapper>
      <div className="dashboard-right">
        {initialLoader.loaded && unpaidInvoices ? (
          <div className="alert alert-warning">
            <p style={{ marginBottom: '10px' }}>
              Er staan één of meerdere onbetaalde facturen op uw account. Om uw
              toegang tot het dashboard te behouden en mogelijke onderbrekingen
              te vermijden, verzoeken we u vriendelijk deze zo spoedig mogelijk
              te voldoen. Onbetaalde facturen leiden tot een tijdelijke blokkade
              van uw dashboard. Betaal vandaag nog om uw diensten voort te
              zetten.
            </p>{' '}
            <button
              type="button"
              onClick={useAllPayment}
              className="TypeSecondary-Rest"
            >
              Facturen Betalen
            </button>
          </div>
        ) : null}

        <div className="dashboard-detail">
          <div className="heading" style={{ marginBottom: '8px' }}>
            <h2>
              <FormattedMessage id="orders" defaultMessage="Orders" />
            </h2>
          </div>
          {/* <DashboardOrders /> */}
          <DashboardOrders orders={ordersData?.ordersList} />
          {ordersData?.ordersList?.length > 0 && (
            <div className="text-end mt-3">
              <Link to="orders" className="Some-link">
                <FormattedMessage id="viewAll" />{' '}
                <img src={chevronRight} alt="" />
              </Link>
            </div>
          )}
        </div>
        <div className="dashboard-detail">
          <MonthlyPerformance />
        </div>

        <div className="dashboard-detail mb-0">
          <DashboardComponentContainer>
            {appLoader.loaded.invoices ? (
              invoiceData?.invoiceList?.length > 0 ? (
                invoiceData?.invoiceList.map((invoice: any) => (
                  <tr key={invoice.id}>
                    <DashboardInvoices invoice={invoice} />
                  </tr>
                ))
              ) : (
                <div className="p-3 bg-transparent text-center border-2">
                  <FormattedMessage id="noInvoicesRecordFound" />
                </div>
              )
            ) : (
              <AppSpinner message="loading" height="45vh" />
            )}
          </DashboardComponentContainer>
          {invoiceData?.invoiceList?.length > 0 && (
            <div className="text-end mt-3">
              <Link to="/finance" className="Some-link">
                <FormattedMessage id="viewAll" />{' '}
                <img src={chevronRight} alt="" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </DashboardWrapper>
  ) : (
    <SubscriptionRequiresPaymentPopup />
  );
};

export default Dashboard;
