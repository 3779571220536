import React from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import Alert from 'react-bootstrap/Alert';

const ModalCheckPayoutStatus = ({
  show,
  handleClose,
  title,
  showSpinner = false,
  paymentStatusCheck,
  setShowPaymentStatusModal
}: any) => {

  return (
    // <Modal dialogClassName="acceptOrderModal" show={show} onHide={handleClose}>
    <Modal show={show} onHide={() => setShowPaymentStatusModal(false)} centered>
      <Modal.Header>
        <Modal.Title>
          <h3>
            {' '}
            <FormattedMessage id={title} />
          </h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      {paymentStatusCheck.isLoading ? (
        <div>
        <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
            </div>
            <div className="d-flex justify-content-center">
                <Alert variant="info" className="mt-3">
                    <FormattedMessage id="checkingPaymentStatusBusyText" />
                </Alert>
                </div>
        </div>
    ) : paymentStatusCheck.isSuccess ? (
      <Alert variant="success">
        <FormattedMessage id={paymentStatusCheck.message} />
      </Alert>
    ) : (
      <Alert variant="danger">
        <FormattedMessage id={paymentStatusCheck.message} />
      </Alert>
    )}
      </Modal.Body>

      {/* <Modal.Footer>
        <button
          type="button"
          className={okButtonDisabled ? 'bnt-TypeDisabled ' : 'Box-type-medium'}
          onClick={okButtonAction}
          disabled={useDisabled&&okButtonDisabled}
        >
          <FormattedMessage id={okButtonText} />
        </button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ModalCheckPayoutStatus;
