import React from 'react';

export const currency = [
  { id: 'EUR', symbol: '€' },
  { id: 'USD', symbol: '$' },
];

const PriceFormatter = ({ amount,symbol }: any) => {
  const x = (amount / 100).toFixed(2);
  const y = x.replace('.', ',');
  let sym = {id:"",symbol:""} as any
if(symbol){  sym = currency.find(
    (cur) => cur.id === symbol
  );}
// console.log("y",y,x,amount)
  return (
    <span>
      {sym!==""&&sym?.symbol} {y!=="NaN"?y:0}
    </span>
  );
};

export default PriceFormatter;
