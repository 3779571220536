import { DepartmentTypes } from "../../Models/DepartmentsModel";
import { DEPARTMENT_DATA, DEPARTMENT_ERROR, SELECTED_DEPARTMENT, SELECTED_SERVICEPOINT, SERVICEPOINT_DATA } from "../constants";

const initialState: DepartmentTypes = {
    departmentsList: [],
    servicepointsList: [],
    selectedDepartment: {},
    selectedServicepoint: {},
    error: "",
};

export default (
    state: DepartmentTypes = initialState,
    { type, payload }: any = {}
) => {
    switch (type) {
        case DEPARTMENT_DATA:
            return { ...state, departmentsList: payload };
        case SELECTED_DEPARTMENT:
            return {
                ...state,
                selectedDepartment: state.departmentsList.find(
                    (list: any) => list.slug === payload
                ),
            };
        case DEPARTMENT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case SERVICEPOINT_DATA:
            return {
                ...state, servicepointsList: payload
            };
        case SELECTED_SERVICEPOINT:
            return {
                ...state,
                selectedServicepoint: state.servicepointsList.find(
                    (list: any) => list.id === payload
                )
            };
        default:
            return state;
    }
};
