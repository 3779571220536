import React from 'react'
import { Spinner } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const AppSpinner = ({message="loading",height="45vh"}:any) => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: height }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="loaderText">
          {' '}
          <FormattedMessage id={message} defaultMessage="Loading..." />
        </p>
      </div>
    </div>
  )
}

export default AppSpinner
