import React from 'react';
import { useDispatch } from 'react-redux';
import { downloadInvoices } from '../../store/actions/InvoicesActions';

import DateFormatter from '../subComponents/DateFormatter';
import PriceFormatter from '../subComponents/PriceFormatter';
import StatusTag from '../subComponents/StatusTag';
// import { Link } from 'react-router-dom';

const DashboardInvoices = ({ invoice }: any) => {
  const dispatch: any = useDispatch();
  const handleSingleDownload = (id: string) => {
    dispatch(downloadInvoices(id));
  };
  return (
    <>
      <td data-cy="invoiceID">
        <h4
          style={{ cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (invoice.status?.name !== 'draft') {
              handleSingleDownload(invoice.id);
            }
          }}
        >
          {' '}
          {invoice.reference_id}
        </h4>
      </td>
      <td>
        <PriceFormatter
          amount={invoice.total_items_price}
          symbol={invoice.currency_code}
        />
      </td>
      <td>
        {invoice.status?.name !== 'draft' && (
          <div>
            <DateFormatter date={invoice.invoiced_at} />
          </div>
        )}
      </td>
      <td>
        <StatusTag status={invoice.status.name} />
      </td>
      <td>
        {invoice.status?.name !== 'draft' && (
          <div>
            <DateFormatter date={invoice.due_at} />
          </div>
        )}
      </td>
    </>
  );
};

export default DashboardInvoices;
