import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import DashboardComponentContainer from "./Dashboard/DashboardComponentContainer";
import Services from "../pages/Services";
import Subscriptions from "../pages/Subscriptions";
import StripeConnectOnboarding from "../pages/StripeConnectOnboarding";
import {getOnboarding} from "../store/actions/DepartmentActions";

const Onboarding = () => {
    const dispatch = useDispatch();
    const onboardingReducer = useSelector((state: any) => state.onboardingReducer);
    const [currentStep, setCurrentStep] = useState<string | null>(null);

    useEffect(() => {
        // This effect will run whenever onboardingReducer changes
        console.log("Onboarding steps changed:", onboardingReducer.onboardingSteps);

        if (onboardingReducer.onboardingSteps.includes('subscription')) {
            setCurrentStep('subscription');
        } else if (onboardingReducer.onboardingSteps.includes('services')) {
            setCurrentStep('services');
        } else if (onboardingReducer.onboardingSteps.includes('stripe-connect')) {
            setCurrentStep('stripe-connect');
        } else {
            setCurrentStep(null);
        }
    }, [onboardingReducer.onboardingSteps]);

    // Function to render the appropriate component
    const renderStepComponent = () => {
        switch (currentStep) {
            case 'subscription':
                return <Subscriptions />;
            case 'services':
                return <Services />;
            case 'stripe-connect':
                return <StripeConnectOnboarding />;
            default:
                return <div>Onboarding has been completed</div>;
        }
    };

    return (
        <div>
            {renderStepComponent()}
        </div>
    );
}

export default Onboarding;