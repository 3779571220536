import React from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const { token, login }: IAuthContext = React.useContext(AuthContext);
  React.useEffect(() => {
    const x = document.getElementById('x');
    if (token === '') {
      if (x) {
        x.click();
      }
    }
    navigate('/');
  }, []);
  return (
    <div>
      {' '}
      <button
        type="button"
        className="visually-hidden"
        id="x"
        onClick={() => login()}
      >
        Login
      </button>
    </div>
  );
};

export default Login;
