import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardMonthlyPerformance from '../components/Dashboard/DashboardMonthlyPerformance';
import AppSpinner from '../components/subComponents/AppSpinner';
import { RootState } from '../store';
import { getMonthlyPerformance } from '../store/actions/MonthlyPerformanceActions';

const MonthlyPerformance = () => {
  const [isDashboard, setIsDashboard] = React.useState(true);
  const metricsData = useSelector((state:RootState)=>state.monthlyReducer)
  const inititalLoader = useSelector((state:RootState)=>state.initialLoaderReducer)
  const dispatch:any = useDispatch();
const appSpinnerLoader = useSelector((state:RootState)=>state.appSpinnerReducer)
  React.useEffect(() => {
    if(inititalLoader.loaded===true){
        dispatch(getMonthlyPerformance())
    }
    if (window.location.href.includes('monthlyPerformance')) setIsDashboard(false);
  // console.log("sds",metricsData)
  }, [inititalLoader]);
  // console.log("app",appSpinnerLoader.loaded.monthlyPerformance)
  return (
    <div className={isDashboard ? '' : 'componentHeightSetter components-card'}>
      <div className={isDashboard ? '' : 'container'}>
        <div className="dashboard-detail">
          <div className="heading">
            <h2>Monthly performance</h2>
          </div>
          {appSpinnerLoader.loaded.monthlyPerformance ? (
            <div>
              <DashboardMonthlyPerformance
                metrics={metricsData}
                isDashboard={isDashboard}
              />
            </div>
          ) : (
            <AppSpinner message="loading" height="45vh" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MonthlyPerformance;
