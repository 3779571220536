import { SET_ALL_SUBSCRIPTIONS_DATA, SET_SUBSCRIPTIONS_LIST, SET_SUBSCRIPTIONS_META } from "../constants";


const initialState = {
    subscriptionsList: [],
    meta:[]
};

export default (
    state: any = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case SET_SUBSCRIPTIONS_LIST:
            return { ...state, subscriptionsList: payload };
        case SET_SUBSCRIPTIONS_META:
            return { ...state, meta: payload };
        case SET_ALL_SUBSCRIPTIONS_DATA:
            return { subscriptionsList:payload.data.data,meta: payload.data.meta };
        default:
            return state;
    }
};
