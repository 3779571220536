import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import smChevronRight from '../../pages/assets/sm_chevron-right.svg';
import smChevronLeft from '../../pages/assets/sm_chevron-left.svg';

const Paginator = ({ meta, dataLength, getPageData, param = [''] }: any) => {
  const dispatch = useDispatch();
  const [records, setRecords] = React.useState({ start: 1, end: dataLength });

  const handleLeftPagination = () => {
    if (meta.page - 1 > 0) {
      if (param[0] === '') {
        dispatch(getPageData(meta.page - 1));
      } else {
        dispatch(getPageData(meta.page - 1, param));
      }
      // setPageNo(meta.page)
      setRecords({
        start: 1 + (meta.page - 1) * 5,
        end: (meta.page - 1) * 5 + dataLength,
      });
    }
  };

  const handleRightPagination = () => {
    if (meta.has_more_pages === true) {
      if (param[0] === '') {
        dispatch(getPageData(meta.page + 1));
      } else {
        dispatch(getPageData(meta.page + 1, param));
      }
      // setPageNo(meta.page)
      setRecords({
        start: 1 + (meta.page - 1) * 5,
        end: (meta.page - 1) * 5 + dataLength,
      });
    }
  };
  React.useEffect(() => {
    const checkbox = document.getElementById('mainCheckBox');
    if (checkbox !== null) {
      //  @ts-ignore
      checkbox.checked = false;
    }
    setRecords({
      start: 1 + (meta.page - 1) * 5,
      end: (meta.page - 1) * 5 + dataLength,
    });
  }, [meta]);
  return (
    <div>
      {meta.total > 0 ? (
        <div className="pagination">
          <ul>
            <li>
              <button
                type="button"
                className={meta.page <= 1 ? 'bnt-TypeDisabled paddingForDisabledBtn' : 'box-btn'}
                onClick={() => handleLeftPagination()}
              >
                <img src={smChevronLeft} alt="" />
              </button>
            </li>
            <li className="pagination-item">
              {records.start} - {records.end} <FormattedMessage id="of" />{' '}
              {meta.total}
            </li>
            <li>
              <button
                type="button"
                className={
                  meta.has_more_pages === true ? 'box-btn' : 'bnt-TypeDisabled paddingForDisabledBtn'
                }
                onClick={() => handleRightPagination()}
              >
                <img src={smChevronRight} alt="" />
              </button>
            </li>
          </ul>
        </div>
      ) : (
        <div className="pagination">
          <ul>
            <li>
              <button type="button" className="bnt-TypeDisabled paddingForDisabledBtn">
                <img src={smChevronLeft} alt="" />
              </button>
            </li>
            <li className="pagination-item">
              0 - 0 <FormattedMessage id="of" /> 0
            </li>
            <li>
              <button type="button" className="bnt-TypeDisabled paddingForDisabledBtn">
                <img src={smChevronRight} alt="" />
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Paginator;
