import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import 'moment/locale/nl';

const DateFormatter = ({
  date,
  hideDate = false,
  showTime = false,
  showDifference = false,
}: string | any) => {
  const curDate = new Date(date);
  const local = localStorage.getItem('locale')
    ? JSON.parse(localStorage.getItem('locale')!)
    : { ISO: 'NL' };
  const [currentDate, setCurrentDate] = React.useState<Date>();
  const departmentReducer = useSelector(
    (state: any) => state.departmentReducer
  );
  const time = moment.utc(date).format('HH:mm');
  React.useEffect(() => {
    setCurrentDate(curDate);
  }, [departmentReducer]);
  const diff = moment.utc(date);

  if (local) diff?.locale(local.locale);
  // console.log("from now",diff.fromNow(),local.locale)
  return (
    <p>
      {local.ISO === 'NL' ? (
        <>
          {currentDate && !hideDate && (
            <>
              {' '}
              <span>
                {currentDate?.getDate() < 10 && 0}
                {currentDate?.getDate()}-
              </span>
              <span>
                {currentDate?.getMonth() < 10 && 0}
                {currentDate.getMonth() + 1}-
              </span>
              <span>{currentDate?.getFullYear()}</span>
            </>
          )}
          {showTime && <span>&nbsp;{time}</span>}&nbsp;&nbsp;
          <span className="badge bg-warning text-dark">
            {showDifference && diff.fromNow()}
          </span>
        </>
      ) : (
        <>
          {currentDate && !hideDate && (
            <>
              <span>{currentDate?.getFullYear()}-</span>
              <span>
                {currentDate.getMonth() + 1 < 10 ? '0' : ''}
                {currentDate.getMonth() + 1}-
              </span>
              {/* <span>
                {currentDate?.getMonth() < 10 && 0}
                {currentDate.getMonth() + 1}-
              </span> */}
              <span>
                {currentDate?.getDate() < 10 && 0}
                {currentDate?.getDate()}
              </span>
            </>
          )}{' '}
          {showTime && <span>&nbsp;{time}</span>}&nbsp;&nbsp;
          <span className="badge bg-warning text-dark">
            {showDifference && diff.fromNow()}
          </span>
        </>
      )}
    </p>
  );
};

export default DateFormatter;
