import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Paginator from '../components/Paginator/Paginator';
import DateFormatter from '../components/subComponents/DateFormatter';
import { exportPayouts, loadPayouts } from '../store/actions/PayoutsAction';
// import smSearch from './assets/sm_search.svg';
import smDownload from './assets/sm_download.svg';
import { downloadInvoices } from '../store/actions/InvoicesActions';
import PriceFormatter from '../components/subComponents/PriceFormatter';
import ModalBs from '../components/subComponents/ModalBs';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import AlertCancel from '../components/Alerts/AlertCancel';
import StatusTag from '../components/subComponents/StatusTag';
import { RootState } from '../store';
import { singlePayout } from '../Models/PayoutsModel';
import AppSpinner from '../components/subComponents/AppSpinner';

const Payouts = () => {
  const dispatch: any = useDispatch();
  const initialLoader = useSelector(
    (state: RootState) => state.initialLoaderReducer
  );
  const payoutData = useSelector((state: RootState) => state.payoutsReducer);
  const userReducer = useSelector((state: RootState) => state.userReducer);
  const appLoader = useSelector((state: RootState) => state.appSpinnerReducer);
  const [payoutIds, setPayoutIds] = React.useState<any>([]);
  // const [disabled, setIsDisabled] = React.useState(false);
  const [exportMsg, setExportMsg] = React.useState(false);
  const [exportErrMsg, setExportErrMsg] = React.useState('');
  const [userEmail, setUserEmail] = React.useState(userReducer.profile.email);
  const [showExportModal, setExportShowModal] = React.useState(false);

  React.useEffect(() => {
    if (initialLoader.loaded === true) {
      setUserEmail(userReducer.profile.email);
      dispatch(loadPayouts());
    }
  }, [initialLoader]);
  const handleCheckAll = (e: any) => {
    const tempPayoutIds = payoutIds;
    if (e.target.checked) {
      for (let i = 0; i < payoutData.payoutsList.length; i++) {
        if (!tempPayoutIds.includes(payoutData.payoutsList[i].id)) {
          tempPayoutIds.push(payoutData.payoutsList[i].id);
        }
        const checkbox = document.getElementById(payoutData.payoutsList[i].id);
        //  @ts-ignore
        if (checkbox !== null) checkbox.checked = true; // This will check all checkboxes
      }
      setPayoutIds(tempPayoutIds);
    } else {
      setPayoutIds([]);
      for (let i = 0; i < payoutData.payoutsList.length; i++) {
        const checkbox = document.getElementById(payoutData.payoutsList[i].id);
        //  @ts-ignore
        if (checkbox !== null) checkbox.checked = false;
      }
    }
  };

  const handleCheckbox = (e: any) => {
    const checkedItemPrice = payoutData.payoutsList.find(
      (item: any) => item.id === e.target.value
    );
    if (e.target.checked) {
      setPayoutIds([...payoutIds, e.target.id]);
    } else {
      const index = payoutIds.indexOf(checkedItemPrice.id);
      if (index !== -1) {
        const temp = payoutIds;
        temp.splice(index, 1);
        setPayoutIds(temp);
      }
    }
  };

  const handleSingleDownload = (id: string) => {
    dispatch(downloadInvoices(id));
  };

  const handleExport = () => {
    const payload = {
      email: userEmail,
      ids: payoutIds,
    };
    if (payoutIds.length !== 0) {
      dispatch(
        exportPayouts(payload, (status: boolean, response: any) => {
          if (status) {
            setExportMsg(true);
            setExportErrMsg('');
            closeModal();
            // setUserEmail(userReducer.profile.email);
          } else {
            setExportErrMsg(response);
            setExportMsg(false);
            closeModal();
            // setUserEmail(userReducer.profile.email);
            // setCancelExportMessage(true)
            console.log('inst', status, response);
          }
        })
      );
    }
  };

  const setEmail = (em: string) => {
    if (em === '') setUserEmail(userReducer.profile.email);
    else setUserEmail(em);
  };
  const closeModal = () => setExportShowModal(false);
  const showModal = () => {
    if (payoutIds.length !== 0) setExportShowModal(true);
    setUserEmail(userReducer.profile.email);
  };

  return (
    <div>
      <div className="container my-4">
        {exportMsg && (
          <AlertSuccess
            id="exportPayoutSuccessMessage"
            payload={{ email: userEmail }}
            fixed={false}
          />
        )}
        {exportErrMsg !== '' && (
          <AlertCancel
            id="exportFailMessage"
            showButton={false}
            action={null}
            actionLabel="blank"
            fixed={false}
            payload={{ msg: exportErrMsg }}
          />
        )}
        <ModalBs
          show={showExportModal}
          handleClose={closeModal}
          title="exportPayoutTitle"
          text="exportPayoutBody"
          okButtonText="download"
          okButtonAction={handleExport}
          cancelButtonText="cancel"
          cancelButtonAction={closeModal}
          inputAction={setEmail}
          inputDefaultValue={userReducer.profile.email}
        />
        <div className="row">
          <div className="col-12">
            <div className="heading-media">
              <div className="heading mb-0">
                <h2>
                  <FormattedMessage id="payouts" defaultMessage="Payouts" />
                </h2>
              </div>
              <div className="heading-right">
                <ol>
                  {/* <li>
                    <button type="button" className="box-btn box-btn-big">
                      <img src={smSearch} alt="" />
                    </button>
                  </li> */}
                  <li>
                    <button
                      type="button"
                      className="Box-type-medium-second"
                      onClick={showModal}
                    >
                      {' '}
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.667 9.333v3.334a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V9.333"
                            stroke="#1469a6"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.333 8 8 11.333 4.667 8M8 11.333V2"
                            stroke="#1469a6"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <p className="d-none d-sm-block"> Download</p>{' '}
                      {/* {downloadpdf !== '' && 1} */}
                    </button>
                  </li>
                </ol>
              </div>
            </div>
            {appLoader.loaded.payouts ? (
              <div className="invoice-table">
                <div className="d-none d-sm-block">
                  <div className="table-responsive table-shadow bg-white">
                    <table className="table-striped .w-auto">
                      <thead>
                        <tr>
                          <th>
                            <input
                              id="mainCheckBox"
                              className="StateChecked "
                              type="checkbox"
                              value=""
                              onClick={handleCheckAll}
                            />
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage id="payoutID" />
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="expectedPayoutDate"
                                defaultMessage="Expected Payout Date"
                              />
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="price"
                                defaultMessage="Price"
                              />
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage id="paidAt" />
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="totalItems"
                                defaultMessage="Total Items"
                              />
                              {/* <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.667 8.666 8 12l3.333-3.334M8 12V4"
                                  stroke="#1469A6"
                                  strokeWidth={1.5}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span> */}
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">Status</p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="actions"
                                defaultMessage="Actions"
                              />
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {payoutData.payoutsList.map((payout: singlePayout) => {
                          return (
                            <tr key={payout.id}>
                              <td>
                                <input
                                  id={payout.id}
                                  className="StateChecked "
                                  type="checkbox"
                                  value={payout.id}
                                  onChange={handleCheckbox}
                                  style={{ cursor: 'pointer' }}
                                  defaultChecked={payoutIds.includes(payout.id)}
                                />
                              </td>
                              <td
                                onClick={() => handleSingleDownload(payout.id)}
                                role="button"
                                tabIndex={0}
                              >
                                <h4 style={{ cursor: 'pointer' }}>
                                  {payout.reference_id}
                                </h4>
                              </td>
                              <td>
                                {' '}
                                <DateFormatter
                                  date={payout.expected_payout_date}
                                />
                              </td>
                              <td>
                                <PriceFormatter
                                  amount={payout.price}
                                  symbol="EUR"
                                />
                              </td>
                              <td>
                                {!!payout.paid_at && (
                                  <DateFormatter date={payout.paid_at} />
                                )}
                              </td>
                              <td>
                                <p> {payout.total_items}</p>
                              </td>
                              <td>
                                <p>
                                  {payout?.status.name !== 'draft' ? (
                                    <StatusTag status={payout.status.name} />
                                  ) : (
                                    <StatusTag
                                      status={`${payout.status.name}Payouts`}
                                    />
                                  )}
                                </p>
                              </td>
                              <td>
                                {payout.status.name !== 'draft' && (
                                  <button
                                    type="button"
                                    className="me-3 box-btn"
                                    onClick={() =>
                                      handleSingleDownload(payout.id)
                                    }
                                  >
                                    <img src={smDownload} alt="" />
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-block d-sm-none">
                  {payoutData.payoutsList.map((payout: singlePayout) => {
                    return (
                      <div
                        key={payout.id}
                        className="components-box components-box-second border-0 mb-2"
                      >
                        <div className="Header-row-card">
                          <div className="Header-row-card-left">
                            <input
                              id={payout.id}
                              className="StateChecked "
                              type="checkbox"
                              value={payout.id}
                              onChange={handleCheckbox}
                              style={{ cursor: 'pointer' }}
                              defaultChecked={payoutIds.includes(payout.id)}
                            />
                          </div>
                          <div className="Header-row-card-right  bg-white">
                            <ul>
                              <li>
                                <div className="label-cell">
                                  <FormattedMessage id="payoutID" />
                                </div>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <a
                                  className="Some-link"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() =>
                                    handleSingleDownload(payout.id)
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  <h4>{payout.reference_id}</h4>
                                </a>
                              </li>
                              <li>
                                <div className="label-cell">
                                  Expected Payout Date
                                </div>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <div className="number-text">
                                  <DateFormatter
                                    date={payout.expected_payout_date}
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="label-cell">Price</div>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <div className="number-text">
                                  <PriceFormatter
                                    amount={payout.price}
                                    symbol="EUR"
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="label-cell">Total Items</div>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <div className="number-text">
                                  {payout.total_items}
                                </div>
                              </li>
                              <li>
                                <div className="label-cell">
                                  <FormattedMessage id="paidAt" />
                                </div>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <div className="number-text">
                                  {!!payout.paid_at && (
                                    <DateFormatter date={payout.paid_at} />
                                  )}
                                </div>
                              </li>

                              <li style={{ textAlign: 'right', width: '100%' }}>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleSingleDownload(payout.id)
                                  }
                                  className="box-btn"
                                >
                                  <img src={smDownload} alt="" />
                                </button>{' '}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <AppSpinner />
            )}
            <div className="sorting-table">
              <p className="sorting">
                <FormattedMessage
                  id="resultsPerView"
                  defaultMessage="Results per view"
                />
                : <strong>&nbsp;25</strong>
              </p>
              <Paginator
                meta={payoutData.meta}
                dataLength={payoutData.payoutsList.length}
                getPageData={loadPayouts}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payouts;
