import React, { useEffect } from 'react';
import { AuthProvider, TAuthConfig } from 'react-oauth2-code-pkce';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppRouter, { MagicRoute, PublicRoute } from './AppRouter';
import webSocket from './services/webSocket.service';
import { RootState } from './store';
import { UPDATE_SOCKET_STATUS } from './store/constants';
import LanguageWrapper from './utils/LanguageWrapper';

export function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.userReducer);
  const { selectedDepartment } = useSelector(
    (state: RootState) => state.departmentReducer
  );
  // @ts-ignore
  const authConfig: TAuthConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID || '', // @ts-ignore

    authorizationEndpoint: localStorage.getItem('magicLogin')
      ? process.env.REACT_APP_OAUTH_AUTHORIZATION_MAGIC_ENDPOINT
      : process.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT,
    // localStorage.getItem('magicLogin') === 'true'
    //   ? `${
    //       process.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT
    //     }${localStorage.getItem('magicLink')}`
    //   : process.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT || '',
    tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
    redirectUri: `${process.env.REACT_APP_URL}/oauth/callback`,
    scope: process.env.REACT_APP_SCOPE,
    ...(localStorage.getItem('magicLink')
      ? {
          extraAuthParameters: {
            'login-signature': localStorage.getItem('magicLink'),
          },
        }
      : ''),
    autoLogin: false,
    // preLogin: () => {if(!localStorage.getItem("origin"))localStorage.setItem("origin",window.location.href)},
    postLogin: () => {
      window.location.replace(localStorage.getItem('origin')!);
      localStorage.removeItem('origin');
      if (localStorage.getItem('magicLink'))
        localStorage.removeItem('magicLink');
      if (localStorage.getItem('magicLogin'))
        localStorage.removeItem('magicLogin');
    },
    // logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT,
    // logoutRedirect: `http://localhost:3000/login`,
  };

  useEffect(() => {
    // Initializing Web Socket
    if (token && Object.keys(selectedDepartment).length) {
      webSocket.initWebSocket(token, selectedDepartment.slug, (val: boolean) =>
        dispatch({ type: UPDATE_SOCKET_STATUS, payload: val })
      );
    }
  }, [token, selectedDepartment]);

  return (
    <div>
      {window.location.href.includes('/invoices/payall') ? (
        <LanguageWrapper>
          <Router>
            <PublicRoute />
          </Router>
        </LanguageWrapper>
      ) : window.location.href.includes('login-signature') ? (
        <AuthProvider authConfig={authConfig}>
          <LanguageWrapper>
            <Router>
              <MagicRoute />
            </Router>
          </LanguageWrapper>
        </AuthProvider>
      ) : (
        <AuthProvider authConfig={authConfig}>
          <LanguageWrapper>
            <div className="mainWrapper">
              <AppRouter />
            </div>
          </LanguageWrapper>
        </AuthProvider>
      )}
    </div>
  );
}

export default App;
