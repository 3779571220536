import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SingleOrder } from '../../Models/Orders.Model';
import { RootState } from '../../store';
import AlertWarning from '../Alerts/AlertWarning';
import AppSpinner from '../subComponents/AppSpinner';
import PriceFormatter from '../subComponents/PriceFormatter';
import StatusTag from '../subComponents/StatusTag';

export function round(value: any, precision: any) {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const DashboardOrders = ({ orders }: { orders: SingleOrder[] }) => {
  const [isDashboard, setIsDashboard] = React.useState(true);

  React.useEffect(() => {
    if (window.location.href.includes('orders')) setIsDashboard(false);
  }, []);
  const ordersData = useSelector((state: RootState) => state.orderReducer);
  const appLoader = useSelector((state: any) => state.appSpinnerReducer);
  const [noRecordFound, setNoRecordFound] = React.useState(false);

  React.useEffect(() => {
    if (appLoader.loaded) {
      if (ordersData.ordersList.length === 0) setNoRecordFound(true);
      else setNoRecordFound(false);
    }
  }, [ordersData]);
  const popoverOnline = (
    <Popover id="popover-trigger-focus">
      <Popover.Body>
        <FormattedMessage id="onlinePayMessage" />
      </Popover.Body>
    </Popover>
  );
  const popoverLocation = (
    <Popover id="popover-trigger-focus">
      <Popover.Body>
        <FormattedMessage id="onLocationPayMessage" />
      </Popover.Body>
    </Popover>
  );
  const popoverPartial = (
    <Popover id="popover-trigger-focus">
      <Popover.Body>
        <FormattedMessage id="partialPayMessage" />
      </Popover.Body>
    </Popover>
  );
  const navigate = useNavigate();
  return (
    <div>
      {appLoader.loaded.orders ? (
        noRecordFound ? (
          <div className="text-center">
            {' '}
            <FormattedMessage id="noOrdersFound" />
          </div>
        ) : (
          // <AlertWarning id="noOrdersFound" />
          <div className="order-detail" style={{ textAlign: 'center' }}>
            {orders.map((order: SingleOrder, idx: number) => {
              return (
                <div
                  key={order.id}
                  className={
                    ordersData.ordersList.length - 1 !== idx ||
                    !window.location.href.includes('orders')
                      ? 'Stacking border-bottom'
                      : 'Stacking'
                  }
                  tabIndex={0}
                  role="button"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/orders/${order.id}`)}
                >
                  <div className="row  align-items-center">
                    <div
                      className={
                        isDashboard
                          ? 'col-lg-3 col-md-3 col-6'
                          : 'col-lg-2 col-md-2 col-6'
                      }
                    >
                      <div className="Stacking-left">
                        <h4>{order.id}</h4>
                      </div>
                    </div>
                    {/* <div className={isDashboard?"col-lg-2 col-md-2 col-6":"col-lg-1 col-md-1 col-6"}>
                          <div className="Stacking-left">
                            <p><DateFormatter date={order.created_at}/> </p>
                          </div>
                        </div>
                        <div className={isDashboard?"col-lg-3 col-md-3 col-6":"col-lg-2 col-md-2 col-6"}>
                          <div className="Stacking-right">
                            <p>Jane Doe</p>
                          </div>
                        </div> */}
                    <div
                      className={
                        isDashboard
                          ? 'col-lg-3 col-md-3 col-6'
                          : 'col-lg-2 col-md-2 col-6'
                      }
                    >
                      <div className="Stacking-right">
                        <span>
                          <StatusTag status={order.status.name} />
                        </span>
                      </div>
                    </div>

                    {!isDashboard && (
                      <>
                        <div className="col-lg-2 col-md-2 col-6">
                          <div className="Stacking-right">
                            {order?.distance ? (
                              <p>
                                {round(order.distance / 1000, 1).toFixed(1)} Km
                              </p>
                            ) : (
                              <p>0 Km</p>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-3 col-6">
                    <div
                      className="Stacking-right"
                      style={{ textAlign: 'left' }}
                    >
                      <ServicesGetter services={order.services} />
                    </div>
                  </div> */}
                      </>
                    )}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="Stacking-right">
                        <p>
                          <PriceFormatter
                            amount={order.meta?.total_price || 0}
                          />
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        isDashboard
                          ? 'col-lg-3 col-md-3 col-6'
                          : 'col-lg-3 col-md-3 col-6'
                      }
                    >
                      <div className="Stacking-right">
                        <div className="paymentMethodBox">
                          {order.payment_method && (
                            <>
                              {order.payment_method === 'online' && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={popoverOnline}
                                >
                                  <div className="paymentMethodIcon" />
                                </OverlayTrigger>
                              )}
                              {order.payment_method === 'partial' && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={popoverPartial}
                                >
                                  <div className="paymentMethodIcon" />
                                </OverlayTrigger>
                              )}
                              {order.payment_method === 'location' && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={popoverLocation}
                                >
                                  <div className="paymentMethodIcon" />
                                </OverlayTrigger>
                              )}
                              <p>
                                {' '}
                                <FormattedMessage id={order.payment_method} />
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )
      ) : (
        <AppSpinner message="loading" height="45vh" />
      )}
    </div>
  );
};
export default DashboardOrders;
