import {
  SET_ACCEPTED_ORDERS_COUNT,
  SET_AVERAGE_ORDER_VALUE,
  SET_AVERAGE_REVIEWS_VALUE,
  SET_CANCELLED_ORDERS_COUNT,
  SET_COMPLETED_ORDERS_COUNT,
  SET_COMPLETED_ORDERS_REVENUE,
  SET_ORDERS_DECLINED,
  SET_ORDERS_LATE_RESPONSE,
  SET_REVIEWS_RECIEVED_COUNT,
  SET_TOTAL_ORDER_COMISSION,
} from '../constants';


const initialState = {
   
};

export default (
    state: any = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
      case SET_COMPLETED_ORDERS_REVENUE:
        return { ...state, revenue: payload.data.data };
      case SET_COMPLETED_ORDERS_COUNT:
        return { ...state, completedOrderCount: payload.data.data };
      case SET_AVERAGE_ORDER_VALUE:
        return { ...state, averageOrderValue: payload.data.data };
      case SET_ORDERS_DECLINED:
        return { ...state, ordersDeclined: payload.data.data };
      case SET_ORDERS_LATE_RESPONSE:
        return { ...state, ordersLateResponse: payload.data.data };
      case SET_REVIEWS_RECIEVED_COUNT:
        return { ...state, countOfReviewsRecieved: payload.data.data };
      case SET_AVERAGE_REVIEWS_VALUE:
        return { ...state, averageReviewsValue: payload };
      case SET_TOTAL_ORDER_COMISSION:
        return { ...state, totalOrderComission: payload };
      case SET_CANCELLED_ORDERS_COUNT:
        return { ...state, cancelledOrdersCount: payload.data.data };
      case SET_ACCEPTED_ORDERS_COUNT:
        return { ...state, acceptedOrdersCount: payload.data.data };
      default:
        return state;
    }
};
