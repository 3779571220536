import React from 'react';
import Invoices from './Invoices';
import Payouts from './Payouts';

const Finance = () => {
  return (
    <div className="containerHeightSetter components-card">
      <div className="container">
        <Invoices />
        <hr />
        <Payouts />
      </div>
    </div>
  );
};

export default Finance;
