import React from 'react';
import { FormattedMessage } from 'react-intl';

const AlertInfo = (props: any) => {
  const { id, payload, fixed } = props;
  return (
    <div className="alert-box alert-info">
      <p className="some-info_blue">
        <span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              clipPath="url(#f0la7sgvaa)"
              stroke="#342fa8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M8 14.666A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.333zM7.993 4.667h.014M8 7.333v4"/>
            </g>
            <defs>
              <clipPath id="f0la7sgvaa">
                <path fill="#fff" d="M0 0h16v16H0z"/>
              </clipPath>
            </defs>
          </svg>
        </span>
        {fixed ? (
          <FormattedMessage id={id} />
        ) : (
          <FormattedMessage id={id} values={payload} />
        )}
      </p>
      {/* <ol>
                                        <li> <a className="Some-link" href="#">Some link <img src="images/sm_arrow-right.svg" alt=""/></a> </li>
                                        <li><button  className="TypePrimary-Rest"> Some action</button></li>
                                    </ol> */}
    </div>
  );
};

export default AlertInfo;
