import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

const Loader = () => {
  console.log('X');
  const { token, login, loginInProgress }: IAuthContext =
    React.useContext(AuthContext);

  React.useEffect(() => {
    const x = document.getElementById('x');
    setTimeout(() => {
      console.log('in loader');
    }, 4000);
    console.log('Sssxa', loginInProgress, token);
    if (token === '') {
      if (x) {
        x.click();
      }
    }
  }, []);
  return (
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: '85vh' }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="loaderText">
          {' '}
          <FormattedMessage id="loading" defaultMessage="Loading..." />
        </p>
        <button
          type="button"
          className="visually-hidden"
          id="x"
          onClick={() => login()}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Loader;
