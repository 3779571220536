import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import DashboardWrapper from './Dashboard/DashboardWrapper';
import Loader from './subComponents/Loader';
import axios from "../utils/Api";


export const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  );

const SubscriptionRequiresPaymentPopup = () => {

    const handlePayment = async () => {
        try {
            // API call to your backend to initiate the payment process
            const response = await axios.put('/current-active-platform-subscription/pay', {
                success_url: window.location.href,
                cancel_url: window.location.href,
            });
            const { session_id, action } = response.data;

            if (action === 'checkout_session_redirect') {
                const stripe = await stripePromise;
                const result = await stripe?.redirectToCheckout({
                    sessionId: session_id,
                });

                if (result && result.error && result.error.message) {
                    console.log('Payment err res', result.error.message);
                  }
            }
        } catch (error) {
            console.error('Error during payment initiation:', error);
        }
    };

    useEffect(() => {
        handlePayment();
    }, []);

    return (
        <DashboardWrapper>
            <Loader/>
        </DashboardWrapper>
    );
  };
  
export default SubscriptionRequiresPaymentPopup;
