import React, { useState } from 'react';

interface SearchbarProps {
  search: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
}

const Searchbar: React.FC<SearchbarProps> = ({
  search,
  handleInputChange,
  handleSubmit,
}) => {
  const isSearchEmpty = search.trim() === '';

  return (
    <div className="row searchBar">
      <div className="col-md-12 mx-auto">
        <div className="input-group">
          <input
            className="form-control bg-white border-end-0 border rounded-pill"
            type="text"
            value={search}
            placeholder={
              window.location.href.includes('orders')
                ? 'Search through orders'
                : 'Search'
            }
            id="example-search-input"
            onChange={handleInputChange}
            style={{ paddingRight: '50px', background: 'none' }}
          />
          <span
            className="input-group-append d-flex flex-column justify-content-center px-2"
            style={{ marginLeft: '-40px' }}
          >
            <button
              className={
                isSearchEmpty
                  ? 'btn btn-outline-custom bg-transparent border-bottom-0 border-white rounded-pill p-1'
                  : 'btn btn-outline-secondary bg-transparent border-bottom-0 border-white rounded-circle p-1'
              }
              type="button"
              onClick={handleSubmit}
              disabled={isSearchEmpty}
            >
              <i className="fa fa-search" style={{ fontSize: '17px' }} />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
