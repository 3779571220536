import React from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import AlertCancel from '../Alerts/AlertCancel';
import AlertSuccess from '../Alerts/AlertSuccess';
import '../ModalService.css';
import Description from './Description';

const ModalService = ({
  show,
  handleClose,
  okButtonAction,
  cancelButtonAction,
  showSpinner,
  showCancelButton=true,
  statusChecked,
  title,
  text,
  onStatusChange,
  showError,
  showMessage,
  originalValue
}: any) => {
 
  const handleCheckboxChange = (event:any) => {
    const newStatus = event.target.checked;
    onStatusChange(newStatus);
  }

  
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="mt-1 border-0" style={{padding:'50px 30px 0px 50px'}} closeButton>
        <Modal.Title>
          <h3>
            {' '}
            <FormattedMessage id={title} />
          </h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="border-0"  style={{padding:'25px 40px 0px 50px' , lineHeight:'1.3'}}>
        {showSpinner ? (
          <Spinner style={{ marginLeft: '44%' }} />
        ) : (
          <>
            {showError === true &&<AlertCancel
                id={showMessage}
              />}
              {showError === false  && (
                    <AlertSuccess
                      id="serviceSuccessFullyActivated"
                    />
                  )}
            
            <Description description={text} />
             
            
            <div className="mt-3 d-flex">
            <p style={{marginTop:'inherit', color:'#496477'}}>Status</p>
            {statusChecked  === true ? 
              <p style={{marginTop:'inherit' , marginLeft:'35px', color:'#219752'}}><FormattedMessage id="statusActive" /></p> :
              <p style={{marginTop:'inherit' , marginLeft:'35px', color:'#496477'}}><FormattedMessage id="statusInactive"  /></p>
              }
              <label style={{marginTop:'16px'}} className="switch mx-2">
                <input type="checkbox" id="togglebutton" defaultChecked={statusChecked}
                 onChange={handleCheckboxChange}
                />
                <span className="slider round"/>
              </label>
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="border-0" style={{justifyContent:'flex-start', padding:'20px 0px 50px 50px'}}>
        {showCancelButton && (
          <button 
            style={{color:'#3482BA' , padding:''}}
            type="button"
            className="TypeNegative-Rest-medium"
            onClick={cancelButtonAction}
          >
            <FormattedMessage id="backButton"/>
          </button>
        )}
        <button
          type="button"
          id="changeStatusBtn"
          className={originalValue!==statusChecked?'Box-type-medium' : 'bnt-TypeDisabled'}
          onClick={okButtonAction}
          disabled={originalValue===statusChecked}
          style={originalValue===statusChecked?{height:"40px"}:{}}
        >
          {statusChecked  === true ? <FormattedMessage id="activateButton"/> : <FormattedMessage id="deactivateButton"/> }
          
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalService;
