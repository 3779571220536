import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AlertCancel from '../Alerts/AlertCancel';
import { RootState } from '../../store';

const TaskTicket = ({
  check,
  action = '',
  actionLabel = 'loading',
  icon = '',
  required,
  translatedActionLabel,
  buttonLabel,
  statusDisabled = false,
  statusPending = false,
  isLoaded,
  loadedText,
}: any) => {
  const [disabledCheck, setDisabledCheck] = React.useState(true);
  const [customDisable, setCustomDisable] = React.useState(false);
  const invoiceData = useSelector((state: RootState) => state.invoiceReducer);
  useEffect(() => {
    if (invoiceData.invoiceList.length <= 0) {
      setCustomDisable(true);
    } else {
      setCustomDisable(false);
    }
  }, [invoiceData]);

  const handleClick = () => {
    if (disabledCheck) {
      setDisabledCheck(false);
      action();
    }
    // setTimeout(()=>{
    //     setDisabledCheck(true)
    // },5000)
  };
  return (
    <div>
      {isLoaded ? (
        <div>
          {!check ? (
            <div className="todo-card mb-2">
              <div className="todo-card-right">
                <p className="Neutral-High-contrast">
                  {icon}{' '}
                  <FormattedMessage
                    defaultMessage="Loading Status"
                    id={actionLabel}
                  />
                </p>
                {statusPending && (
                  <div className="mt-3">
                    <AlertCancel id="debitButtonWarning" />
                  </div>
                )}

                {statusDisabled ? null : buttonLabel === 'payAll' ? (
                  <div className="media mb-0">
                    <button
                      type="button"
                      onClick={handleClick}
                      className={
                        customDisable
                          ? 'bnt-typeDisabled'
                          : disabledCheck
                          ? 'TypeSecondary-Rest'
                          : 'bnt-TypeDisabled'
                      }
                      disabled={customDisable || check}
                    >
                      <FormattedMessage id={buttonLabel} />
                    </button>

                    {required && (
                      <button type="button" className="TypeWarning pe-none">
                        <FormattedMessage id="required" />
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="media mb-0">
                    <button
                      type="button"
                      onClick={handleClick}
                      className={
                        disabledCheck
                          ? 'TypeSecondary-Rest'
                          : 'bnt-TypeDisabled'
                      }
                      disabled={check}
                    >
                      <FormattedMessage id={buttonLabel} />
                    </button>

                    {required && (
                      <button type="button" className="TypeWarning pe-none">
                        <FormattedMessage id="required" />
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="type-success-right  task-complete completedTask">
              <p className="Neutral-High-contrast">
                {icon} <FormattedMessage id={translatedActionLabel} />
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="type-success-right  task-complete completedTask">
          <p className="Neutral-High-contrast">
            {icon} <FormattedMessage id={loadedText} />
          </p>
        </div>
      )}
    </div>
  );
};
export default TaskTicket;
