import { Employees } from "../../Models/EmployeesModel";
import { SET_ALL_EMPLOYEES_DATA, SET_EMPLOYEES_LIST, SET_EMPLOYEES_META } from "../constants";


const initialState:Employees = {
    employeesList: [],
    meta:[]
};

export default (
    state: Employees = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case SET_EMPLOYEES_LIST:
            return { ...state, employeesList: payload };
        case SET_EMPLOYEES_META:
            return { ...state, meta: payload };
        case SET_ALL_EMPLOYEES_DATA:
            return { employeesList:payload.data.data,meta: payload.data.meta };
        // case UPDATE_EMPLOYEE:
        //     state.employeesList.map((emp:any)=>{if(emp.id===payload.data.data.id) return emp=payload.data.data})
        //     console.log("dx",payload.data.data,state)
        //     return state;
        default:
            return state;
    }
};
