import { Component } from "react";
import Pusher from "pusher-js";

declare const window: any;
class WebSocket extends Component {
  static initWebSocket(token: string, department: string, updateSocketStatus: any) {
    const pusher = new Pusher("partners", {
      cluster: "APP_CLUSTER",
      enabledTransports: ['ws', 'xhr_streaming'],
      wsHost: process.env.REACT_APP_WEBSOCKET_BASE_URL,
      authEndpoint: `${process.env.REACT_APP_PARTNER_API_ENDPOINT}/oauth/websocket`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Department": department,
          Accept: "application/json",
        },
      },
      wssPort: 6001,
      disableStats: true,
      forceTLS: true,
    });
    pusher.connection.bind('connected', () => {
      updateSocketStatus(true)
    });

    pusher.connection.bind('error', () => {
      updateSocketStatus(false)
    });
    window.Pusher = pusher;
  }
}

export const socketEventCallback = (eventName: string, data: any) => {
  console.log(
    `bind global: The event ${eventName} was triggered with data ${JSON.stringify(
      data
    )}`
  );
};

export default WebSocket;