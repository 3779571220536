import axios from "../../utils/Api";
import {  SET_SERVICES_DATA } from "../constants";

export const getServices = () => (dispatch:any) => {
    
    axios
        .get("/settings/services")
        .then((response:any)=>{
            dispatch({
                type: SET_SERVICES_DATA,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })    
}

export const addService = () => {}