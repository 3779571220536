import React from 'react'
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { IsoCodes } from './constants/Languages';
import english from './translate/en.json';
import dutch from './translate/nl.json';

export const LangContext = React.createContext<MyContext | null>(null);

interface MyContext {
    language: string | any;
    languageSetter: (ln: string) => void;
  }

const LanguageWrapper = ({children}:any) => {
    const [language, setLanguage] = React.useState<any>(english);
      const userReducer = useSelector(
        (state: any) => state.userReducer
      );
    React.useEffect(() => {
    if (userReducer.locale.name !== 'English') setLanguage(dutch);
    else setLanguage(english);
    }, []);
    
    const languageSetter = (ln: any) => {
        if (ln === 'English') {
          setLanguage(english);
        } else {
          setLanguage(dutch);
        }
      };
      const value = React.useMemo(() => ({
        language, setLanguage, languageSetter
      }), [language]);
    const locale = navigator.language;
    
  return (
    <LangContext.Provider value={value}>
    <IntlProvider
      locale={locale}
      defaultLocale={IsoCodes.en}
      messages={language}
    >
      {children}
    </IntlProvider>
  </LangContext.Provider>
  )
}

export default LanguageWrapper