import axios from "../../utils/Api";
import { doAPICallWithCallBack } from "../../utils/helper";
import { SET_ALL_INVOICE_DATA } from "../constants";
import { setInvoicesLoader } from "./AppSpinnerActions";

export const getInvoices =  (page = 1,limit=25) => (dispatch: any) => {
  dispatch(setInvoicesLoader(false))
    axios
    .get(`/invoices?limit=${limit}&page=${page}`)
    .then((response: any) => {
        dispatch({
            type: SET_ALL_INVOICE_DATA,
            payload: response,
        })
        dispatch(setInvoicesLoader(true))
    })
    .catch((error) => {
        console.log("xmenErr",error)
        dispatch(setInvoicesLoader(true))
        
    });
}

export const downloadInvoices = (id:string) =>() => {
    axios
    .get(`/invoices/${id}/download`)
    .then((response:any)=>{
        window.open(response.data.data.url)
    })
    .catch((error)=>{
        console.log("err",error)
    })
}

export const payInvoice = (
    id: number | string,
    data: any,
    callback: (status:any, res:any) => void
  ) => {
    return () => {
      const url = id === "pay-all" ? `/invoices/pay-all` : `/invoices/${id}/pay`;
      axios
        .put(url, data)
        .then((response: any) => {
          callback(true, response.data);
        })
        .catch((error: any) => {
          callback(
            false,
            error.response ? error.response.data.message : "Something went wrong."
          );
        });
    };
  };
  
  export const exportInvoices = (data:any,callBack:any) => () => {
    return doAPICallWithCallBack("/invoices/exports", callBack, 'post',data)
  }