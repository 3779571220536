import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import AlertCancel from '../Alerts/AlertCancel';
import AlertSuccess from '../Alerts/AlertSuccess';

const ModalService = ({
  show,
  handleClose,
  title,
  showError,
  cancelButtonAction,
  cancelButtonText,
}: any) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="mt-1 border-0" style={{padding:'50px 30px 0px 50px'}}>
        <Modal.Title className='h5'>
          <h3>
            {' '}
            <FormattedMessage id={title} />
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-1 border-0" style={{padding:'50px 30px 0px 50px'}}>
            {showError === true &&<AlertCancel
                id="debitButtonWarning"
              />}
            {showError === false  && (
                  <AlertSuccess
                    id="debitTextCompleted"
                  />
                )}
      </Modal.Body>

      <Modal.Footer className="border-0" style={{justifyContent:'flex-start', padding:'20px 0px 50px 50px'}}>
        <button
            type="button"
            className="Box-type-medium"
            onClick={cancelButtonAction}
          >
            <FormattedMessage id={cancelButtonText} />
          </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalService;