import React from 'react';
import { FormattedMessage } from 'react-intl';

const AlertWarning = (props: any) => {
  const { id, payload, fixed } = props;
  return (
    <div className="alert-box alert-Warning">
      <p className="some-warning_color">
        <span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m6.28 2.893-4.8 8.087A2 2 0 0 0 3.2 14h9.613a2 2 0 0 0 1.72-3.02L9.72 2.893a2 2 0 0 0-3.44 0v0zM8 11.333h.007M8 6.667v2.667"
              stroke="#ca4716"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        {fixed ? (
          <FormattedMessage id={id} />
        ) : (
          <FormattedMessage id={id} values={payload} />
        )}
      </p>
      {/* <ol>
        <li>
          {' '}
          <a className="Some-link" href="/#">
            Some link <img src="images/sm_arrow-right.svg" alt="" />
          </a>{' '}
        </li>
        <li>
          <button className="TypePrimary-Rest"> Some action</button>
        </li>
      </ol> */}
    </div>
  );
};

export default AlertWarning;
