import axios from "../../utils/Api";
import { SET_ACCEPTED_ORDERS_COUNT, SET_AVERAGE_ORDER_VALUE, SET_AVERAGE_REVIEWS_VALUE, SET_CANCELLED_ORDERS_COUNT, SET_COMPLETED_ORDERS_COUNT, SET_COMPLETED_ORDERS_REVENUE, SET_ORDERS_DECLINED, SET_ORDERS_LATE_RESPONSE, SET_REVIEWS_RECIEVED_COUNT } from "../constants";
import { setMonthlyLoader } from "./AppSpinnerActions";

export const getMonthlyPerformance = () => (dispatch:any) => {
    dispatch(setMonthlyLoader(false))
    axios
        .get("/statistics/monthly/revenue-of-completed-orders")
        .then((response:any)=>{
            dispatch({
                type: SET_COMPLETED_ORDERS_REVENUE,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/count-of-completed-orders")
        .then((response:any)=>{
            dispatch({
                type: SET_COMPLETED_ORDERS_COUNT,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/average-order-value")
        .then((response:any)=>{
            dispatch({
                type: SET_AVERAGE_ORDER_VALUE,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/orders-declined")
        .then((response:any)=>{
            dispatch({
                type: SET_ORDERS_DECLINED,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/orders-late-response")
        .then((response:any)=>{
            dispatch({
                type: SET_ORDERS_LATE_RESPONSE,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/count-of-reviews-received")
        .then((response:any)=>{
            dispatch({
                type: SET_REVIEWS_RECIEVED_COUNT,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/average-reviews-value")
        .then((response:any)=>{
            dispatch({
                type: SET_AVERAGE_REVIEWS_VALUE,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/count-of-cancelled-orders")
        .then((response:any)=>{
            dispatch({
                type: SET_CANCELLED_ORDERS_COUNT,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        axios
        .get("/statistics/monthly/count-of-accepted-orders")
        .then((response:any)=>{
            dispatch({
                type: SET_ACCEPTED_ORDERS_COUNT,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })
        // axios
        // .get("/statistics/monthly/total-order-commission")
        // .then((response:any)=>{
        //     dispatch({
        //         type: SET_TOTAL_ORDER_COMISSION,
        //         payload: response,
        //     })
        // })
        // .catch((error)=>{
        //     console.log("err",error)
        // })
        setTimeout(()=>{
            // console.log("in tim")
            dispatch(setMonthlyLoader(true))
        },1000)
  //  dispatch(setMonthlyLoader(true))
        
}
