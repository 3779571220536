import React from 'react';
import { useSelector } from 'react-redux';
import DashboardSideBar from './DashboardSideBar';
import '../Layout.css';

const DashboardWrapper = ({ children }: any) => {
  const departmentReducer = useSelector(
    (state: any) => state.departmentReducer
  );

  return (
      <div className="dashboard">
        <DashboardSideBar departmentReducer={departmentReducer}/>
        {children}
      </div>
  );
};

export default DashboardWrapper;
