import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../pages/assets/logo.svg';
import smChevronDown from '../pages/assets/sm_chevron-down.svg';
import phoneIcon from '../pages/assets/sm_phone.svg';
import {
    setPartnerDepartmentStartAsync,
    switchLanguage
} from '../store/actions/DepartmentActions';
import languageData from '../store/data';
import { LangContext } from '../utils/LanguageWrapper';

const LoggedOutNavbar = () => {
  const { token }: IAuthContext = React.useContext(AuthContext);

  // const [showMenu, setShowMenu] = React.useState(false);
  React.useEffect(() => {
    if (JSON.parse(localStorage.getItem('ROCP_token')!)) {
      dispatch(setPartnerDepartmentStartAsync());
    }
  }, [localStorage.getItem('ROCP_token')]);
  const userReducer = useSelector(
    (state: any) => state.userReducer
  );
  const dispatch: any = useDispatch();
  const MyContext = React.useContext(LangContext);

  const handleLanguage = (data: any) => {
    dispatch(switchLanguage(data));
    const ln = data.name;
    MyContext?.languageSetter(ln);
  };
 
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!token && !window.location.href.includes('code')) {
      navigate('/');
    }
  }, []);
  
 
 
 
  const popoverLeft = (
    <Popover id="popover-trigger-focus">
      <Popover.Body>
        <a className="Some-link" href="tel:+31882222050">
          088 2222 050
        </a>
      </Popover.Body>
    </Popover>
  );

  return (
    <div style={{ position: 'sticky', top: '0', zIndex: '1' }}>
      <header className="header-second">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="header-inner">
                <div className="header-left">
                  <div className="logo">
                    <Link to="/#">
                      <img
                        src={logo}
                        alt=""
                        className="img-fluid"
                        style={{ minWidth: '148px' }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="header-right">
                  <div className="service-list">
                    <ol>
                      <li>
                        <OverlayTrigger
                          trigger="focus"
                          placement="bottom"
                          overlay={popoverLeft}
                        >
                          <a className="call-btn">
                            <img src={phoneIcon} alt="" className="img-fluid" />{' '}
                            <FormattedMessage id="call" />
                          </a>
                        </OverlayTrigger>
                      </li>
                    </ol>
                  </div>
                  <div className="language-list">
                    <ol className="d-none d-md-flex">
                      {languageData.map((data) => (
                        <li
                          key={data.languageId}
                          className={
                            userReducer.locale.ISO === data.ISO
                              ? 'active'
                              : ''
                          }
                        >
                          <button
                            type="button"
                            className="links a-to-btn"
                            onClick={() => {
                              handleLanguage(data);
                            }}
                          >
                            {data.ISO}
                          </button>
                        </li>
                      ))}
                    </ol>
                    <div className="HeightSmall dropdown d-block d-md-none">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {userReducer.locale.ISO}
                        <span>
                          <img
                            src={smChevronDown}
                            alt=""
                            className="img-fluid"
                          />
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {languageData.map((lang) => {
                          return (
                            <li key={lang.ISO}>
                              <button
                                type="button"
                                onClick={() => {
                                  handleLanguage(lang);
                                }}
                                className={
                                  userReducer.locale.ISO === lang.ISO
                                    ? 'links a-to-btn dropdown-item StateDisabled '
                                    : 'links a-to-btn dropdown-item '
                                }
                              >
                                {lang.ISO}
                              </button>
                            </li>
                          );
                        })}
                        {/* <li>
                          <Link className="dropdown-item StateDisabled " to ="/#">
                            NL
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default LoggedOutNavbar;
