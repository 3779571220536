import {
  SET_APP_SPINNER_LOADER,
  SET_EMPLOYEES_LOADER,
  SET_INVOICES_LOADER,
  SET_MONTHLY_LOADER,
  SET_ORDERS_LOADER,
  SET_PAYOUTS_LOADER,
} from '../constants';

const initialState: { loaded: any } = {
  loaded: {
    orders: false,
    invoices: false,
    monthlyPerformance: false,
    employees: false,
    payouts: false,
  },
};

export default (
  state: { loaded: any } = initialState,
  { type, payload }: any = {}
) => {
  switch (type) {
    case SET_APP_SPINNER_LOADER:
      return { loaded: payload };
    case SET_ORDERS_LOADER:
      // debugger
      // @ts-ignore
      return {
        ...state,
        loaded: {
          ...state.loaded,
          orders: payload,
        },
      };
    case SET_INVOICES_LOADER:
      // debugger
      // @ts-ignore
      return {
        ...state,
        loaded: {
          ...state.loaded,
          invoices: payload,
        },
      };
    case SET_EMPLOYEES_LOADER:
      // debugger
      // @ts-ignore
      return {
        ...state,
        loaded: {
          ...state.loaded,
          employees: payload,
        },
      };

    case SET_MONTHLY_LOADER:
      // debugger
      // @ts-ignore
      return {
        ...state,
        loaded: {
          ...state.loaded,
          monthlyPerformance: payload,
        },
      };
    case SET_PAYOUTS_LOADER:
      // debugger
      // @ts-ignore
      return {
        ...state,
        loaded: {
          ...state.loaded,
          payouts: payload,
        },
      };

    default:
      return state;
  }
};
