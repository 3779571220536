import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import {
  createEmployee,
  deleteEmployee,
  getEmployees,
  updateEmployeeNotification,
} from '../store/actions/EmployeesAction';
import trashIcon from './assets/trash.svg';
import Paginator from '../components/Paginator/Paginator';
import ModalBs from '../components/subComponents/ModalBs';
import AlertCancel from '../components/Alerts/AlertCancel';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import { RootState } from '../store';
import { singleEmployee } from '../Models/EmployeesModel';
import AppSpinner from '../components/subComponents/AppSpinner';
// import smChevronDown from './assets/sm_chevron-down.svg';

const Employees = () => {
  const initialLoader = useSelector((state: RootState) => state.initialLoaderReducer);
  const departmentReducer = useSelector(
    (state: RootState) => state.departmentReducer
  );
  const userReducer = useSelector(
    (state: RootState) => state.userReducer
  );
  const employeesData = useSelector((state: RootState) => state.employeesReducer);

  const dispatch: any = useDispatch();
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showNotificationToast, setShowNotificationToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [employeeId, setEmployeeId] = useState<any>();
  const [notificationToast, setNotificationToast] = React.useState('');
  const [employeeInfo, setEmployeeInfo] = React.useState({
    name: '',
    companyName: '',
  });
  const [showExportModal, setExportShowModal] = React.useState(false);
  const [emailError,setEmailError] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState(
    userReducer.profile.email
  );
  const appLoader = useSelector((state:RootState)=>state.appSpinnerReducer)
  const [createEmployeeSuccess,setCreateEmployeeSuccess] = React.useState(false)
  const [createEmployeeFail,setCreateEmployeeFail] = React.useState(false)

  useEffect(() => {
    if (initialLoader.loaded === true) {
      dispatch(getEmployees());
      setUserEmail(userReducer.profile.email);
    }
  }, [initialLoader]);

  const handleClose = () => setShow(!show);
  const handleConfirm = (employee: any) => {
    setEmployeeInfo({
      name: `${employee.first_name} ${employee.last_name}`,
      companyName: departmentReducer.selectedDepartment.name,
    });

    setIsLoading(true);
    dispatch(
      deleteEmployee(employeeId, (status: boolean, response: any) => {
        handleClose();
        if (status) {
          dispatch(getEmployees());
          setError('');
        } else {
          setError(response);
        }
        setShowToast(true);
        setIsLoading(false);
      })
    );
  };

  const handleNotification = (e: any, employee: singleEmployee) => {
    const { id } = employee;
    setEmployeeInfo({
      name: `${employee.first_name} ${employee.last_name}`,
      companyName: departmentReducer.selectedDepartment.name,
    });
    setIsLoading(true);
    dispatch(
      updateEmployeeNotification(
        id,
        (status: boolean, response: any) => {
          if (status) {
            dispatch(getEmployees());
            setError('');
            if (response.data.notifications === true)
              setNotificationToast('acceptEmployeeNotification');
            else {
              setNotificationToast('cancelEmployeeNotification');
              setError('');
            }
          } else {
            setError('errorEmployeeNotification');
            e.target.checked = !e.target.checked;
          }
          setShowNotificationToast(true);
          setIsLoading(false);
        },
        { status: e.target.checked }
      )
    );
  };
  const setEmail = (em: string) => {
    setUserEmail(em);
    if (em === '') setEmailError(true);
    else {
      setEmailError(false);
    }
  };
  const closeModal = () => {
    setExportShowModal(false);
  };
  const showModal = () => {
    setCreateEmployeeSuccess(false)
    setCreateEmployeeFail(false)
    setExportShowModal(true);
    setUserEmail(userReducer.profile.email);
  };
  const handleCreateEmployee = () => {
    dispatch(createEmployee({email:userEmail},(status:boolean,response:AxiosResponse)=>{
      if(status){
        setCreateEmployeeSuccess(true)
        setCreateEmployeeFail(false);
        closeModal()
        // console.log("create",response)
      }
      else{
        setCreateEmployeeSuccess(false)
        setCreateEmployeeFail(true);
        closeModal()
        console.log("failed",response)
      }
    }))
  }
  return (
    <div className="components-card">
    <div className="container">
    {createEmployeeFail&&<AlertCancel
            id="createEmployeeFail"
            showButton={false}
            action={null}
            actionLabel="blank"
            fixed
          />}
     {createEmployeeSuccess && (
          <AlertSuccess
            id="createEmployeeSuccess"
            fixed={false}
            payload={{ email: userEmail }}
          />
        )}
    <ModalBs
          show={showExportModal}
          handleClose={closeModal}
          title="createEmployee"
          text="createEmployeeBodyText"
          okButtonText="create"
          okButtonAction={handleCreateEmployee}
          cancelButtonText="cancel"
          cancelButtonAction={closeModal}
          inputAction={setEmail}
          inputDefaultValue=" "
          showInputError={emailError}
            inputErrorMessage="required"
        />
    <div className="heading-media">
      <div className="heading mb-0">
        <h2>
          <FormattedMessage id="employees" defaultMessage="Employees" />
        </h2>
      </div>
      <div className="heading-right">
                <ol>
                  <li>
                    <button
                      type="button"
                      className="Box-type-medium-second"
                      onClick={showModal}
                    >
                      <p className="d-none d-sm-block"> <FormattedMessage id="createEmployee"/></p>{' '}
                      {/* {downloadpdf !== '' && 1} */}
                    </button>
                  </li>
                </ol>
              </div>
      </div>
      {appLoader.loaded.employees?<div className="invoice-table">
        <div className="d-none d-sm-block">
          <div className="table-responsive table-shadow bg-white">
            <table className="table-striped .w-auto">
              <thead>
                <tr>
                  <th>
                    <p className="sorting ">
                      <FormattedMessage id="profile" />
                    </p>
                  </th>
                  <th>
                    <p className="sorting ">
                      <FormattedMessage id="name" />
                      {/* <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.667 8.666 8 12l3.333-3.334M8 12V4"
                                      stroke="#1469A6"
                                      strokeWidth={1.5}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span> */}
                    </p>
                  </th>
                  <th>
                    <p className="sorting ">
                      <FormattedMessage id="phone" />
                      {/* <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.667 8.666 8 12l3.333-3.334M8 12V4"
                                      stroke="#1469A6"
                                      strokeWidth={1.5}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span> */}
                    </p>
                  </th>
                  <th>
                    <p className="sorting ">
                      <FormattedMessage id="notifications" />
                      {/* <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.667 8.666 8 12l3.333-3.334M8 12V4"
                                      stroke="#1469A6"
                                      strokeWidth={1.5}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span> */}
                    </p>
                  </th>
                  <th>
                    <p className="sorting d-none d-lg-block">
                      <FormattedMessage id="actions" />
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {employeesData.employeesList.map((employee: singleEmployee) => {
                  return (
                    <tr key={employee.id}>
                      <td>
                        <img
                          src={employee.avatar}
                          className="table-avatar"
                          alt=""
                        />
                      </td>
                      <td>{`${employee.first_name} ${employee.last_name}`}</td>
                      <td>{employee.phone}</td>
                      <td>
                        <div className="form-check form-switch ">
                          <input
                            style={{marginTop:"14px"}}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={(e: any) => {
                              handleNotification(e, employee);
                            }}
                            defaultChecked={employee.notifications}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="box-btn"
                          onClick={() => {
                            setEmployeeId(employee.id);
                            setEmployeeInfo({
                              name: `${employee.first_name} ${employee.last_name}`,
                              companyName:
                                departmentReducer.selectedDepartment.name,
                            });
                            handleClose();
                          }}
                        >
                          <img
                            src={trashIcon}
                            className=""
                            style={{ maxWidth: '19px' }}
                            alt="trashicon"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-block d-sm-none">
          {employeesData.employeesList.map((employee: singleEmployee) => {
            return (
              <div
                key={employee.id}
                className="components-box components-box-second border-0 mb-2"
              >
                <div className="Header-row-card">
                  
                  <div className="Header-row-card-right  bg-white">
                    <ul>
                      <li>
                        <a href="/#" className="Some-link">
                          {`${employee.first_name} ${employee.last_name}`}
                        </a>
                      </li>
                      <li style={{ textAlign: 'right' }}>
                        <img
                          src={employee.avatar}
                          className="table-avatar"
                          style={{ top: 0 }}
                          alt=""
                        />
                      </li>
                      <li>
                        <div className="label-cell">Phone</div>
                      </li>
                      <li style={{ textAlign: 'right' }}>
                        <p className="number-text">{employee.phone}</p>
                      </li>
                      <li>
                        <div className="label-cell">Notifications</div>
                      </li>
                      <li style={{ display:"flex",justifyContent:"end" }}>
                        <div className="form-check form-switch mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            defaultChecked={employee.notifications}
                            onChange={(e: any) => {
                              handleNotification(e, employee);
                            }}
                          />
                        </div>
                      </li>
                      {/* <li>
                                <p className="Neutral-High-contrast">
                                  
                                 ID : {employee.id}
                                </p>
                              </li> */}
                       
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sorting-table">
          <p className="sorting">
            <FormattedMessage id="resultsPerView" />
            :&nbsp;<strong>5</strong>
          </p>
          <Paginator
            meta={employeesData.meta}
            dataLength={employeesData.employeesList.length}
            getPageData={getEmployees}
          />
        </div>
      </div>:
      <AppSpinner message="loading" height="45vh"/>}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={isLoading ? 'loading' : 'delete'}
              defaultMessage="Delete"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={isLoading ? 'd-flex justify-content-center' : ''}
        >
          {!isLoading ? (
            <FormattedMessage
              id="deleteConfirm"
              values={{
                empName: employeeInfo.name,
                empCompanyName: employeeInfo.companyName,
              }}
            />
          ) : (
            <Spinner animation="border" variant="primary" />
          )}
        </Modal.Body>
        {!isLoading && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="close" defaultMessage="close" />
            </Button>
            <Button variant="danger" onClick={handleConfirm}>
              <FormattedMessage id="delete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <ToastContainer
        position="top-end"
        className="employee-toast position-fixed"
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Header className={error ? 'text-danger' : 'text-success'}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">
              <FormattedMessage
                id={error ? 'error' : 'success'}
                defaultMessage="Success"
              />
            </strong>
          </Toast.Header>
          <Toast.Body>
            {error || (
              <FormattedMessage
                id="alert.employeeDeleted"
                defaultMessage="Employee Deleted"
              />
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <ToastContainer
        position="top-end"
        className="employee-toast position-fixed"
      >
        <Toast
          onClose={() => setShowNotificationToast(false)}
          show={showNotificationToast}
          delay={4000}
          autohide
        >
          <Toast.Header className={error ? 'text-danger' : 'text-success'}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">
              <FormattedMessage
                id={error ? 'error' : 'success'}
                defaultMessage=""
              />
            </strong>
          </Toast.Header>
          <Toast.Body>
            {' '}
            {error === '' ? (
              <>
                {employeeInfo.name}{' '}
                <FormattedMessage
                  id={notificationToast}
                  // defaultMessage="Employee Deleted"
                />
              </>
            ) : (
              <>
                {' '}
                <FormattedMessage id={error} /> {employeeInfo.name}
              </>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
    </div>
  );
};

export default Employees;
