import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PercentageCalculator from '../subComponents/PercentageCalculator';
import PriceFormatter from '../subComponents/PriceFormatter';
import StarRater from '../subComponents/StarRater';
import chevronRight from '../../pages/assets/sm_arrow-right.svg';
import DifferenceCalculator from '../subComponents/DifferenceCalculator';

const DashboardMonthlyPerformance = (props: any) => {
  const { isDashboard, metrics } = props;
  // console.log('metrics', metrics);
  const checkRecord = Object.keys(metrics).every((key) => {
    const metric = metrics[key];
    if (key === 'averageReviewsValue') {
      return (
        metric.data?.data?.value === 0 && metric.data?.data?.prev_value === 0
      );
    } else {
      return metric.value === 0 && metric.prev_value === 0;
    }
  });
  // Rendering centered message if all metrics are zero
  if (checkRecord) {
    return (
      <div className="text-center p-5">
        <FormattedMessage id="noPerformanceRecordFound" />
      </div>
    );
  }
  return (
    <div>
      <div className="metrics-performance">
        <ol>
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                <FormattedMessage id="revenue" /> 💰
              </p>

              {metrics?.revenue && (
                <>
                  <h4 className="Data-type-numnber">
                    <PriceFormatter
                      amount={metrics?.revenue.value}
                      symbol="EUR"
                    />
                  </h4>
                  <div className="media">
                    <p className="ComparisonDown---Neutral">
                      {' '}
                      <PriceFormatter
                        amount={metrics?.revenue.prev_value}
                        symbol="EUR"
                      />{' '}
                    </p>
                    <PercentageCalculator
                      finalValue={metrics?.revenue.value}
                      previousValue={metrics?.revenue.prev_value}
                    />
                  </div>
                </>
              )}
            </div>
          </li>
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                # <FormattedMessage id="ordersCompleted" /> 🧾
              </p>
              {metrics?.completedOrderCount && (
                <>
                  <h4 className="Data-type-numnber">
                    {metrics?.completedOrderCount.value}
                  </h4>

                  <div className="media">
                    <p className="ComparisonDown---Neutral">
                      {' '}
                      {metrics?.completedOrderCount.prev_value}
                    </p>
                    <PercentageCalculator
                      finalValue={metrics?.completedOrderCount.value}
                      previousValue={metrics?.completedOrderCount.prev_value}
                    />
                  </div>
                </>
              )}
            </div>
          </li>
          {/* Orders Accepted onbekend */}

          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                <FormattedMessage id="ordersAccepted" />
              </p>
              <h4 className="Data-type-numnber">
                {metrics?.acceptedOrdersCount?.value}
              </h4>
              <div className="media">
                <p className="ComparisonDown---Neutral">
                  {metrics?.acceptedOrdersCount?.prev_value}
                </p>
                <PercentageCalculator
                  finalValue={metrics?.acceptedOrdersCount?.value}
                  previousValue={metrics?.acceptedOrdersCount?.prev_value}
                />
              </div>
            </div>
          </li>
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                <FormattedMessage id="ordersDeclined" />
              </p>
              {metrics?.ordersDeclined && (
                <>
                  <h4 className="Data-type-numnber">
                    {metrics.ordersDeclined.value}
                  </h4>
                  <div className="media">
                    <p className="ComparisonDown---Neutral">
                      {metrics.ordersDeclined.prev_value}
                    </p>
                    <PercentageCalculator
                      finalValue={metrics.ordersDeclined.value}
                      previousValue={metrics.ordersDeclined.prev_value}
                    />
                  </div>
                </>
              )}
            </div>
          </li>
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                <FormattedMessage id="ordersLate" />
              </p>
              {metrics?.ordersLateResponse && (
                <>
                  <h4 className="Data-type-numnber">
                    {metrics.ordersLateResponse.value}
                  </h4>
                  <div className="media">
                    <p className="ComparisonDown---Neutral">
                      {metrics.ordersLateResponse.prev_value}
                    </p>
                    <PercentageCalculator
                      finalValue={metrics.ordersLateResponse.value}
                      previousValue={metrics.ordersLateResponse.prev_value}
                      inverseColor
                    />
                  </div>
                </>
              )}
            </div>
          </li>
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                <FormattedMessage id="avgOrdersValue" />
              </p>
              {metrics?.averageOrderValue && (
                <>
                  <h4 className="Data-type-numnber">
                    <PriceFormatter
                      amount={metrics.averageOrderValue.value}
                      symbol="EUR"
                    />
                  </h4>
                  <div className="media">
                    <p className="ComparisonDown---Neutral">
                      <PriceFormatter
                        amount={metrics.averageOrderValue.prev_value}
                        symbol="EUR"
                      />
                    </p>
                    <PercentageCalculator
                      finalValue={metrics.averageOrderValue.value}
                      previousValue={metrics.averageOrderValue.prev_value}
                    />
                  </div>
                </>
              )}
            </div>
          </li>
          {/* Reveiews Amount onbekend */}
          {/* <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">Reviews amount</p>
              <h4 className="Data-type-numnber">€ onbekend</h4>
              <div className="media">
                <p className="ComparisonDown---Neutral">€ 10.463</p>
                <p className="ComparisonDown---Positive">
                  {' '}
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.333 9.333V4.667H6.667M4.667 11.333l6.666-6.666"
                        stroke="#08873f"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  10%
                </p>
              </div>
            </div>
          </li> */}
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                <FormattedMessage id="reviewsAmount" />
              </p>
              {metrics?.averageReviewsValue && (
                <>
                  <h4 className="Data-type-numnber">
                    <p>
                      {metrics?.averageReviewsValue.value
                        ? metrics?.averageReviewsValue.value
                        : 0}
                    </p>
                  </h4>
                  <div className="media">
                    <p className="ComparisonDown---Neutral">
                      {metrics?.averageReviewsValue.prev_value
                        ? metrics?.averageReviewsValue.prev_value
                        : 0}
                    </p>
                    <DifferenceCalculator
                      finalValue={metrics?.averageReviewsValue.value}
                      previousValue={metrics?.averageReviewsValue.prev_value}
                    />
                  </div>
                </>
              )}
            </div>
          </li>
          {/* Total Fees Paid On bekend */}
          {/* <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">Total fees paid</p>
              <h4 className="Data-type-numnber">€ onbekend</h4>
              <div className="media">
                <p className="ComparisonDown---Neutral">€ 8.472</p>
                <p className="ComparisonDown---Positive">
                  {' '}
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.333 9.333V4.667H6.667M4.667 11.333l6.666-6.666"
                        stroke="#08873f"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  5%
                </p>
              </div>
            </div>
          </li> */}
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                <FormattedMessage id="avgReviews" />
                {metrics?.countOfReviewsRecieved && (
                  <span>({metrics.countOfReviewsRecieved.value})</span>
                )}
              </p>
              {/* star rating onbekend */}
              {/* {metrics?.averageReviewsValue&&ratingRounder(metrics.averageReviewsValue.value)} */}
              {metrics?.averageReviewsValue && (
                <StarRater rating={metrics.averageReviewsValue.value} />
              )}
            </div>
          </li>
          <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">
                # <FormattedMessage id="ordersCancelled" />
              </p>
              {metrics?.cancelledOrdersCount && (
                <>
                  <h4 className="Data-type-numnber">
                    {metrics.cancelledOrdersCount.value}
                  </h4>
                  <div className="media">
                    <p className="ComparisonDown---Neutral">
                      {metrics.cancelledOrdersCount.prev_value}
                    </p>
                    <PercentageCalculator
                      finalValue={metrics.cancelledOrdersCount.value}
                      previousValue={metrics.cancelledOrdersCount.prev_value}
                      inverseColor
                    />
                  </div>
                </>
              )}
            </div>
          </li>
          {/* Performance ABC onbekend */}
          {/* <li className="performance-item">
            <div className="Metric-item">
              <p className="metric-title">Performance Abc</p>
              <div className="data-type-progress">
                <div className="progress">
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '25%' }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-label="progress-bar"
                    />
                  }
                </div>
              </div>
              <div className="media">
                <p className="ComparisonDown---Neutral">84% onbekend</p>
              </div>
            </div>
          </li> */}
        </ol>
      </div>
      {isDashboard && (
        <div className="text-end mt-3">
          <Link className="Some-link" to="monthlyPerformance">
            <FormattedMessage id="viewAll" /> <img src={chevronRight} alt="" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default DashboardMonthlyPerformance;
