import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import DepartmentReducer from "./reducers/DepartmentReducer";
import EmployeesReducer from "./reducers/EmployeesReducer";
import InitialLoaderReducer from "./reducers/InitialLoaderReducer";
import InvoiceReducer from "./reducers/InvoiceReducer";
import OrdersReducer from "./reducers/OrdersReducer";
import ReviewsReducer from "./reducers/ReviewsReducer";
import ServicesReducer from "./reducers/ServicesReducer";
import PayoutReducer from "./reducers/PayoutReducer"
import SubscriptionReducer from "./reducers/SubscriptionReducer";
import MonthlyPerformanceReducer from "./reducers/MonthlyPerformanceReducer";
import AppSpinnerReducer from "./reducers/AppSpinnerReducer";
import UserReducer from "./reducers/UserReducer";
import OnboardingReducer from "./reducers/OnboardingReducer";

export const store = configureStore({
  reducer: {
    departmentReducer: DepartmentReducer,
    invoiceReducer: InvoiceReducer,
    orderReducer: OrdersReducer,
    employeesReducer: EmployeesReducer,
    subscriptionsReducer: SubscriptionReducer,
    servicesReducer: ServicesReducer,
    reviewsReducer: ReviewsReducer,
    userReducer: UserReducer,
    initialLoaderReducer: InitialLoaderReducer,
    appSpinnerReducer: AppSpinnerReducer,
    payoutsReducer: PayoutReducer,
    monthlyReducer: MonthlyPerformanceReducer,
    onboardingReducer: OnboardingReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
