import React, {useEffect} from 'react';
import axios from '../utils/Api';
import AlertInfo from "../components/Alerts/AlertInfo";
import alertWarning from "../components/Alerts/AlertWarning";

const StripeConnectOnboarding = () => {
    useEffect(() => {
        if (window.location.search.includes('returned_from_stripe')) {
            console.log('the user has returned from stripe');
        }
    }, []);

    const handleOnboardingLink = () => {
        axios.post('/settings/stripe-connect-issue-onboarding-link', {
            refresh_url: window.location.href + "?refresh=true",
            return_url: window.location.href + "?returned_from_stripe=true"
        })
            .then(response => {
                if (response.data && response.data.data && response.data.data.url) {
                    window.location.href = response.data.data.url;
                } else {
                    alertWarning({id: "errorMessageOnboarding"});
                }
            })
            .catch(error => {
                console.error('Error fetching onboarding link:', error);
            });
    };

    return (
        <div>
            <div className="stripe-container">
                <div className="container">
                    <AlertInfo id="stripeConnectOnboardingMessage"/>
                    <h1>Verbind nu met stripe</h1>
                    <button
                        type="button"
                        onClick={handleOnboardingLink}
                        className="TypeSecondary-Rest"
                    >
                        Onboard
                    </button>
                </div>
            </div>
        </div>
    );
    // return (
    //
    //     <div className="container">
    //         <div>
    //
    //         </div>
    //     </div>
    // );
};


export default StripeConnectOnboarding;