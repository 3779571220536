
export const ratingRounder = (input=0) => {
    const rating = input / 10;
    const integer = Math.floor(rating);
    const decimal = (rating - integer).toFixed(1);
    let convertedRating=0;
    
    switch (decimal) {
        case '0.1':
          convertedRating=integer
          break;
        case '0.2':
          convertedRating=integer
          break;
        case '0.3':
          convertedRating=integer + 0.5
          break;
        case '0.4':
          convertedRating=integer + 0.5
          break;
        case '0.5':
          convertedRating=integer + 0.5
          break;
        case '0.6':
          convertedRating=integer + 0.5
          break;
        case '0.7':
          convertedRating=integer + 0.5
          break;
        case '0.8':
          convertedRating=integer + 1
          break;
        case '0.9':
          convertedRating=integer + 1
          break;
        default:
          convertedRating=integer
          break;
      }
      return convertedRating;
}
