import React from 'react'

const PercentageCalculator = ({
  finalValue = 0,
  previousValue = 0,
  inverseColor = false,
}: any) => {
  const [positive, setPositive] = React.useState(true);
  const [isNeutral, setIsNeutral] = React.useState(true);
  const percentage = ((finalValue - previousValue) / previousValue) * 100;
  // console.log("per",percentage,finalValue,previousValue)
  React.useEffect(() => {
    if (finalValue === previousValue) setIsNeutral(true);
    else if (previousValue === 0) setIsNeutral(true);
    else setIsNeutral(false);

    if (percentage < 0) setPositive(false);
    // console.log("positive",finalValue,previousValue,percentage,positive)
  }, []);
  return (
    <div>
      {!isNeutral ? (
        <p
          className={
            !inverseColor?
            (positive 
              ? 'ComparisonDown---Positive'
              : 'ComparisonDown---Negative'):
              (positive 
              ? 'ComparisonDown---Negative'
              : 'ComparisonDown---Positive')  
          }
        >
          {' '}
          <span>
            {positive ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.333 9.333V4.667H6.667M4.667 11.333l6.666-6.666"
                  stroke={inverseColor ? '#AE1313' : '#08873f'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.667 11.333h4.666V6.667M4.667 4.667l6.666 6.666"
                  stroke={inverseColor ? '#08873f' : '#AE1313'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
          {Math.round(Math.abs(percentage))}%
        </p>
      ) : (
        <p className="ComparisonDown---Neutral">&nbsp; 0%</p>
      )}
    </div>
  );
};

export default PercentageCalculator