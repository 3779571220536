const languageData = [
    {
        languageId: 'dutch',
        locale: 'nl',
        name: 'Dutch',
        icon: 'nl',
        ISO: 'NL'
    },
    {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
        ISO: 'EN'
    }
];
export default languageData;
