import React from 'react';
import { ratingRounder } from './MathHelper';

const StarRater = ({ rating }: any) => {
  const starArray = ['', '', '', '', ''];
  return (
    <div>
      {!rating ? (
        <div className="data-type-rating mt-3">
          <ol>
            {starArray.map(() => {
              return (
                <li key={Math.random()}>
                  <a className="rating-star">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.973 4.59a.5.5 0 0 0-.434-.338l-2.85-.227-1.234-2.73a.499.499 0 0 0-.91-.001L4.31 4.025l-2.85.227a.5.5 0 0 0-.31.856l2.106 2.054-.744 3.225a.5.5 0 0 0 .764.529L6 9.101l2.723 1.815a.5.5 0 0 0 .758-.553l-.915-3.2 2.268-2.041a.5.5 0 0 0 .14-.532z"
                        fill="#EBEDEE"
                      />
                    </svg>
                  </a>
                </li>
              );
            })}
          </ol>
          <span>{ ratingRounder(rating)}</span>
        </div>
      ) : (
        <div className="data-type-rating mt-3">
          <ol>
            {starArray.map((star: any, idx: number) => {
              // const x = ratingRounder(45)
              idx += 1;
              // const decimal = (x - Math.floor(x)).toFixed(1)

              // console.log("jam",decimal,ratingRounder(45),idx,ratingRounder(45)<=idx)
              // console.log("jx",((ratingRounder(45)-(Math.floor(ratingRounder(45)))).toFixed(1)))
              return (
                ratingRounder(rating) >= idx && (
                  <li key={Math.random()}>
                    <a className="rating-star">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.973 4.59a.5.5 0 0 0-.434-.338l-2.85-.227-1.234-2.73a.499.499 0 0 0-.91-.001L4.31 4.025l-2.85.227a.5.5 0 0 0-.31.856l2.106 2.054-.744 3.225a.5.5 0 0 0 .764.529L6 9.101l2.723 1.815a.5.5 0 0 0 .758-.553l-.915-3.2 2.268-2.041a.5.5 0 0 0 .14-.532z"
                          fill="#3482ba"
                        />
                      </svg>
                    </a>
                  </li>
                )
              );
            })}

            {(
              ratingRounder(rating) - Math.floor(ratingRounder(rating))
            ).toFixed(1) === '0.5' && (
              <li>
                <a className="rating-star">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.973 4.59a.5.5 0 0 0-.434-.338l-2.85-.227-1.234-2.73a.499.499 0 0 0-.91-.001L4.31 4.025l-2.85.227a.5.5 0 0 0-.31.856l2.106 2.054-.744 3.225a.5.5 0 0 0 .764.529L6 9.101l2.723 1.815a.5.5 0 0 0 .758-.553l-.915-3.2 2.268-2.041a.5.5 0 0 0 .14-.532z"
                      fill="url(#zsnbcs7o8a)"
                    />
                    <defs>
                      <linearGradient
                        id="zsnbcs7o8a"
                        x1=".75"
                        y1="7.5"
                        x2="11.rating"
                        y2="7.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset=".5" stopColor="#3482BA" />
                        <stop offset=".501" stopColor="#EBED/EE" />
                        <stop offset="1" stopColor="#EBEDEE" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
              </li>
            )}
            {starArray.map((star: any, idx: number) => {
              idx += 1;
              return (
                Math.ceil(ratingRounder(rating)) < idx && (
                  <li>
                    <a className="rating-star">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.973 4.59a.5.5 0 0 0-.434-.338l-2.85-.227-1.234-2.73a.499.499 0 0 0-.91-.001L4.31 4.025l-2.85.227a.5.5 0 0 0-.31.856l2.106 2.054-.744 3.225a.5.5 0 0 0 .764.529L6 9.101l2.723 1.815a.5.5 0 0 0 .758-.553l-.915-3.2 2.268-2.041a.5.5 0 0 0 .14-.532z"
                          fill="#EBEDEE"
                        />
                      </svg>
                    </a>
                  </li>
                )
              );
            })}
          </ol>
         <span>{ ratingRounder(rating)}</span>
        </div>
      )}
    </div>
  );
};

export default StarRater;
