import React from 'react';
// import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import AlertCancel from '../components/Alerts/AlertCancel';
import Bell from './assets/bell.svg';
import Trash from './assets/trashred.svg';
import axios from '../utils/Api';

const Profile = () => {
  const departmentReducer = useSelector((state: RootState) => state.departmentReducer)
  // const { profile } = departmentReducer
  const [form, setForm] = React.useState({});
  const [disabled,setDisabled] = React.useState(true);
  const [showSuccessStatus,setShowSuccessStatus] = React.useState<boolean>();
  const [showErrorStatus,setShowErrorStatus] = React.useState<boolean>();
  const [showUploadMessage,setShowUploadMessage] = React.useState("");
  const userReducer = useSelector((state: RootState) => state.userReducer)
  const { profile } = userReducer
  console.log(profile)
  // const handleFileChange = (uploadFile: any) => {
  //   if (!uploadFile) {
  //     // return;
  //     console.log('inhandle')
  //   }
  // }
  const handleChange = (field: string, value: any) => {
    setForm({ ...form, [field]: value });
    setDisabled(false);
  };

  const handleUpdate = () => {
    setShowSuccessStatus(false)
    setShowErrorStatus(false)
    console.log('form', form);
    axios.patch('/profile' , form)
    .then((response: any) => {
     console.log('resp' , response)
     setShowSuccessStatus(true)
     setShowUploadMessage("Profile Updated Successfully")
    })
    .catch((err: any) => {
      setShowErrorStatus(true)
      setShowUploadMessage("There was some error ")
      console.log('error', err);
    });
  };

  const handleDelete = () => {
    setShowSuccessStatus(false)
    setShowErrorStatus(false)
    axios.delete('/profile/avatar')
    .then((response: any) => {
      console.log('response', response);
     setShowSuccessStatus(true)
     setShowUploadMessage("Avatar Deleted Successfully")
    })
    .catch((err: any) => {
      setShowErrorStatus(true)
      setShowUploadMessage(err.response.data.message)
      console.log('error', err);
    });
  };

  return (
    <div>
      {Object.keys(profile).length > 1 && (
        <div className="components-card">
          <div className="container">
              {showSuccessStatus && (
              <AlertSuccess
                id={showUploadMessage}
              />
            )}
            {showErrorStatus && (
              <AlertCancel
              id={showUploadMessage}
              />
            )}
            <div className="heading-media">
              <div className="heading mb-0">
                <h2>
                  <FormattedMessage id="profileHeading" defaultMessage="profileHeading" />
                </h2>
              </div>
              <div className="heading-right">
                <ol>
                  <li>
                    <button
                      type="button"
                      className="Box-type-medium-second"
                    >
                      {' '}
                      <span className='mt-1'>
                         <img src={Bell} alt="" />
                      </span>
                      <p className="d-none d-sm-block"> Manage Notifications</p>{' '}
                    </button>
                  </li>
                </ol>
              </div>
            </div>
                <div className="col-lg-6 col-md-12 col-sm-12 mt-5">
                  <p className="profileText mb-5 mx-2">Update your profile pic</p>
                  <div className="d-flex settingsMediaBox justify-content-between">
                    <div className="img-container d-flex w-100">
                      <div
                        style={{
                          background: profile.avatar ? `url(${profile.avatar})` : '#C4C4C4',
                          height: '152px',
                          width: '152px',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          borderRadius: '50%'
                        }}
                      />
                      {profile.avatar?
                        <div style={{ alignSelf: 'center', marginLeft:'30px'}}>
                          <button type='button' className='Box-type-medium'>Change Image</button>
                          <button type='button' style={{color:'#AE1313'}} className='TypeNegative-Rest-medium mt-2'
                          onClick={handleDelete}>
                             <span className='mt-1 mx-1'>
                               <img src={Trash} alt="" /> 
                            </span> 
                            Delete</button>
                        </div> :
                        <div style={{ alignSelf: 'center', marginLeft:'30px'}}>
                          <button type='button' className='Box-type-medium'>Upload Image</button>
                        </div> 
                      }
                    </div>
                  </div>
                </div>
              <div className="mb-4 col-lg-6 col-sm-10 mt-5">
                <label className='col-form-label profileText'>First Name</label>
                <input id="firstname" type="text" className="form-control" defaultValue={profile.first_name}
                 onChange={(e) => {
                  handleChange('first_name', e.target.value);
                }}/>
              </div>
              <div className="mb-4 col-lg-6 col-sm-10 my-1">
                <label className='col-form-label profileText'>Last Name</label>
                <input type="text" className="form-control" defaultValue={profile.last_name} 
                onChange={(e) => {
                  handleChange('last_name', e.target.value);
                }}/>
              </div>
              <div className="mb-4 col-lg-6 col-sm-10 my-1">
                <label className='col-form-label profileText'>Email</label>
                <input type="email" className="form-control" defaultValue={profile.email} disabled/>
              </div>
              <div className="mb-4 col-lg-6 col-sm-10 my-1">
                <label className='col-form-label profileText'>Gender</label>
                <input type="text" className="form-control" defaultValue={profile.gender} 
                onChange={(e) => {
                  handleChange('gender', e.target.value);
                }}/>
              </div>
              <div className="mb-4 col-lg-6 col-sm-10 my-1">
                <label className='col-form-label profileText'>Phone </label>
                <input type="text" className="form-control" defaultValue={profile.phone} disabled/>
                <p className='col-form-label profileTextPhone'>Update Phone Number</p>
              </div>
              <div className="mb-4 col-lg-6 col-sm-10 my-1">
                <label className='col-form-label profileText'>Street Name, Number </label>
                <input type="text" className="form-control" defaultValue={profile.street_number} />
              </div>
              <button type='button' className="Box-type-medium"
               onClick={handleUpdate}
               disabled={disabled}
               >Save Profile</button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Profile;
