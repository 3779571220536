import React from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Dropzone from './Dropzone';

const InvoiceModal = (props:any) => {
    const {
      showFinishPopup,
      togglePopup,
      handleFileChange,
      error,
      setAmount,
      handleFinish,
    } = props ;
  return (
    <div className="invoice-modal">
      <Modal show={showFinishPopup} onHide={togglePopup} centered>
        <Modal.Body>
          <div className="invoice-media">
            <div className="info-holder">
              <span>
                <svg
                  fill="#e75b27"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  width="64px"
                  height="64px"
                >
                  <path d="M6,64c0,32,26,58,58,58s58-26,58-58S96,6,64,6S6,32,6,64z M61,41c0-1.7,1.3-3,3-3s3,1.3,3,3v5c0,1.7-1.3,3-3,3s-3-1.3-3-3 V41z M61,61c0-1.7,1.3-3,3-3s3,1.3,3,3v26c0,1.7-1.3,3-3,3s-3-1.3-3-3V61z" />
                </svg>
              </span>
            </div>
            <div className="text-box">
              <h2><FormattedMessage id='invoiceModalQuestion'/></h2>
              <p><FormattedMessage id="invoiceModalBodyMessage" />
              &nbsp;
              <a
                href="https://vandaag.services/algemene-voorwaarden-bedrijven/"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="termsAndConditions" />
              </a>
              &nbsp;5.13
              </p> 
            </div>
          </div>
          <div className="invoice-modal-inner">
            <div className="dropping-box">
              <h4 className="label-text">
                <FormattedMessage id="invoice" />
              </h4>
              <Dropzone handleDrop={handleFileChange} />
              <span className="errorMsg">
                {' '}
                {error !== '' && (
                  <FormattedMessage
                    defaultMessage=" "
                    id={error === 'blank' ? 'blank' : error}
                  />
                )}
              </span>
            </div>
            <div className="form-group">
              <div className="label-text">
                <FormattedMessage id='totalPriceIncludingVat'/> <sup>*</sup>{' '}
              </div>
              <input
                type="text"
                className="form-control amount-form text-start"
                placeholder="0.00€"
                onChange={(e) => setAmount(e.target.value)}
              />
              <p className="required-text mt-3">*<FormattedMessage id='required'/></p>
            </div>
            <div className="media mt-3">
              <div className="d-flex">
                <div className="me-3">
                  <button
                    type="button"
                    onClick={togglePopup}
                    className="Box-type-medium-second"
                  >
                    <FormattedMessage id='close'/>
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleFinish}
                    className="Box-type-medium"
                  >
                    <FormattedMessage id='finish'/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default InvoiceModal