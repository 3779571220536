import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {Button} from "react-bootstrap";
import { getServices } from '../store/actions/ServicesActions';
import ModalService from '../components/subComponents/ModalService';
import axios from '../utils/Api';
import Filter from './assets/sm_filter.svg';
// import WhiteFilter from './assets/white-filter.svg';
import Searchbar from '../components/Searchbar';
import { RootState } from '../store';
import AlertInfo from "../components/Alerts/AlertInfo";
import AlertWarning from "../components/Alerts/AlertWarning";
import { getOnboarding } from "../store/actions/DepartmentActions";

const Services = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [categories, setCategories] = React.useState<string[]>([]);
  const [selectCategories, setSelectCategories] = React.useState<string[]>([]);
  const [selectedService, setSelectedService] = React.useState(null);

  //  const [filteredResponse, setFilteredResponse] = React.useState([]);
  const [filteredResponse, setFilteredResponse] = React.useState<any[]>([]);

  const initialLoader = useSelector(
    (state: RootState) => state.initialLoaderReducer
  );
  const serviceData = useSelector((state: any) => state.servicesReducer);
  const [showExportModal, setExportShowModal] = React.useState(false);
  const [activeChecked, setActiveChecked] = React.useState(false);
  const [inactiveChecked, setInactiveChecked] = React.useState(false);
  const [modalData, setModalData] = React.useState({
    id: '',
    title: '',
    desc: '',
    originalValue: false,
  });
  const [statusChecked, setStatusChecked] = React.useState<boolean>(false);
  const [spinnerModal, setSpinnerModal] = React.useState(false);
  const [error, setError] = React.useState<boolean>();
  const [respMsg, setRespMsg] = React.useState('');

  const onboardingReducer = useSelector((state: any) => state.onboardingReducer)

  React.useEffect(() => {
    if (initialLoader.loaded) {
      dispatch(getServices());
      axios.get(`/settings/services/categories`).then((response: any) => {
        setCategories(response.data);
      });
    }
  }, [initialLoader]);
  React.useEffect(() => {
    const filteredServiceData = serviceData.servicesList;
    setFilteredResponse(filteredServiceData);
    // Apply any active filters here
    getFilteredServices(selectCategories);
  }, [serviceData, selectCategories, activeChecked, inactiveChecked]);

  React.useEffect(() => {
    if (selectedService && serviceData.servicesList) {
      const updatedService = serviceData.servicesList.find(
          (service: any) => service.id === selectedService
      );
      if (updatedService) {
        setStatusChecked(!!updatedService.service.enabled);
        setModalData(prevData => ({
          ...prevData,
          originalValue: !!updatedService.service.enabled
        }));
      }
    }
  }, [serviceData, selectedService]);

  const closeModal = () => setExportShowModal(false);
  const handleCheckboxClick = (e: any, categoryName: any) => {
    let temp = selectCategories;
    if (e.target.checked) {
      if (!selectCategories.includes(categoryName)) {
        setSelectCategories([...selectCategories, categoryName]);
        temp.push(categoryName);
      }
    } else {
      const tempCategories = selectCategories;
      const index = tempCategories.indexOf(categoryName);
      if (index !== -1) {
        tempCategories.splice(index, 1);
      }
      temp = tempCategories;
      setSelectCategories(tempCategories);
    }
    getFilteredServices(temp);
  };


  const fetchServices = () => {
    dispatch(getServices());
  };

  const handleActiveCheckboxClick = () => {
    setActiveChecked(!activeChecked);
    setInactiveChecked(false);
  };
  const handleInactiveCheckboxClick = () => {
    setInactiveChecked(!inactiveChecked);
    setActiveChecked(false);
  };
  React.useEffect(() => {
    getFilteredServices(selectCategories);
  }, [activeChecked, inactiveChecked, selectCategories]);

  const showModal = (serviceId: any, name: any, desc: any, status: any) => {
    setError(undefined);
    setRespMsg('');
    const serviceEnabledStatus = !!status;
    setModalData({
      id: serviceId,
      title: name,
      desc: desc === null ? 'No Description' : desc,
      originalValue: serviceEnabledStatus,
    });
    setStatusChecked(serviceEnabledStatus);
    setSelectedService(serviceId);
    setExportShowModal(true);
  };
  const getFilteredServices = (search: string[]) => {
    if (search.length !== 0) {
      const tempFilteredServices = serviceData.servicesList.filter(
        (service: any) => search.includes(service.category.id)
      );
      setFilteredResponse(tempFilteredServices);

      if (activeChecked && !inactiveChecked) {
        const temp = tempFilteredServices.filter(
          (item: any) => item.service.enabled !== null
        );

        setFilteredResponse(temp);
      }

      if (!activeChecked && inactiveChecked) {
        const temp = tempFilteredServices.filter(
          (item: any) => item.service.enabled === null
        );
        setFilteredResponse(temp);
      }
    } else {
      setFilteredResponse(serviceData.servicesList);
      if (activeChecked && !inactiveChecked) {
        const temp = serviceData.servicesList.filter(
          (item: any) => item.service.enabled !== null
        );

        setFilteredResponse(temp);
      }

      if (!activeChecked && inactiveChecked) {
        const temp = serviceData.servicesList.filter(
          (item: any) => item.service.enabled === null
        );
        setFilteredResponse(temp);
      }
    }
  };

  const handleStatusChange = (newStatus: boolean) => {
    setStatusChecked(newStatus);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (!event.target.value.trim()) {
      setFilteredResponse(serviceData.servicesList);
    }
  };

  const handleOnboardingNextStep = () => {
    dispatch(getOnboarding())
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    console.log(`Searching for: ${searchTerm}`);
    axios
      .get(`/settings/services?query=${searchTerm}`)
      .then((response: any) => {
        console.log('After Search Found ', response.data.data);
        setFilteredResponse(response.data.data);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleUpdate = () => {
    setSpinnerModal(true);
    const updateMethod = statusChecked ? 'post' : 'delete';
    const url = statusChecked ? '/settings/services' : `/settings/services/${modalData.id}`;
    const data = statusChecked ? { service_id: modalData.id } : undefined;

    axios[updateMethod](url, data)
        .then((response: any) => {
          setError(false);
          setRespMsg(response.data.message);
          fetchServices(); // Refetch services after successful update
        })
        .catch((err: any) => {
          setError(true);
          setRespMsg(err.response.data.message);
        })
        .finally(() => {
          setSpinnerModal(false);
        });
  };

  return (
    <div className="containerHeightSetter components-card">
      <div className="container">
        <AlertWarning id="selectingServicesIsRequiredForOnboarding" />
        <ModalService
          show={showExportModal}
          handleClose={closeModal}
          title={modalData.title}
          text={modalData.desc}
          okButtonText={statusChecked ? "Deactivate" : "Activate"}
          statusChecked={statusChecked}
          onStatusChange={handleStatusChange}
          okButtonAction={handleUpdate}
          cancelButtonText="Back"
          cancelButtonAction={closeModal}
          showSpinner={spinnerModal}
          showError={error}
          showMessage={respMsg}
          originalValue={modalData.originalValue}
        />
        <div className="row">
          <div className="container">
            <div className="heading-media">
              <div className="heading mb-0">
                <h2>
                  <FormattedMessage id="services" defaultMessage="Services" />
                </h2>
              </div>
              <div className="heading-right">
                <ol>
                  <Searchbar
                    search={searchTerm}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                  />
                  <div className="HeightSmall dropdown">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                    >
                      <span
                        style={{
                          borderRadius: '50%',
                          width: '40px',
                          height: '40px',
                        }}
                      >
                        <img src={Filter} alt="" />
                        {/* <img src={WhiteFilter} alt=""/> */}
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {categories.map((category: any) => {
                        return (
                          <li key={category.id}>
                            <div className="dropdown-item d-flex mt-2 mb-2">
                              <input
                                type="checkbox"
                                id={category.name}
                                name={category.name}
                                value={category.id}
                                onChange={(e) =>
                                  handleCheckboxClick(e, category.id)
                                }
                                defaultChecked={false}
                              />
                              <label
                                className="mx-3 filter-text"
                                htmlFor={category.name}
                              >
                                {category.name}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                      <li>
                        <div className="dropdown-item d-flex mt-2 mb-2">
                          <input
                            type="checkbox"
                            id="activeCheckbox"
                            name="activeCheckbox"
                            checked={activeChecked}
                            onChange={handleActiveCheckboxClick}
                          />
                          <label
                            className="mx-3 filter-text"
                            htmlFor="activeCheckbox"
                          >
                            Active
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="dropdown-item d-flex mt-2 mb-2">
                          <input
                            type="checkbox"
                            id="inactiveCheckbox"
                            name="inactiveCheckbox"
                            checked={inactiveChecked}
                            onChange={handleInactiveCheckboxClick}
                          />
                          <label
                            className="mx-3 filter-text"
                            htmlFor="inactiveCheckbox"
                          >
                            Inactive
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </ol>
              </div>
            </div>
            <div className="invoice-table">
              <div className="">
                {loading ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ width: '100%' }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="table-responsive table-shadow bg-white">
                    <table className="table-striped .w-auto">
                      <thead>
                        <tr>
                          <th aria-label="serviceTitle">
                            <p className="sorting ">
                              <FormattedMessage id="serviceTitle" />
                            </p>
                          </th>
                          <th aria-label="serviceCategory">
                            <p className="sorting ">
                              <FormattedMessage id="serviceCategory" />
                            </p>
                          </th>
                          <th aria-label="serviceState">
                            <p className="sorting ">
                              <FormattedMessage id="serviceState" />
                            </p>
                          </th>
                          <th aria-label="price">
                            <p className="sorting ">
                              <FormattedMessage id="price" />
                            </p>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredResponse?.map((service: any) => {
                          return (
                            <tr key={service.id}>
                              <td>
                                <p> {service.name}</p>
                              </td>
                              <td aria-label="categoryName">
                                <p>
                                  <FormattedMessage
                                    id={`categoryName.${service.category.name
                                      .toLowerCase()
                                      .replace(/ /g, '_')}`}
                                  />
                                </p>
                              </td>
                              <td>
                                {service.service.force_disabled === true ? (
                                  <p>
                                    <span
                                      className="p-2"
                                      style={{
                                        backgroundColor: '#EBEDEE',
                                        color: '#8D9BA5',
                                        fontWeight: '600',
                                        borderRadius: '10px',
                                        marginRight: '2px',
                                      }}
                                    >
                                      {' '}
                                      <FormattedMessage id="disabled" />
                                    </span>
                                  </p>
                                ) : (
                                  <p
                                    role="button"
                                    id="statustext"
                                    tabIndex={0}
                                    onClick={() =>
                                      showModal(
                                        service.id,
                                        service.name,
                                        service.description,
                                        service.service.enabled
                                      )
                                    }
                                  >
                                    <span
                                      className="p-2"
                                      style={
                                        service.service.enabled === null
                                          ? {
                                              backgroundColor: '#EBEDEE',
                                              color: '#8D9BA5',
                                              fontWeight: '600',
                                              borderRadius: '10px',
                                            }
                                          : {
                                              backgroundColor: '#DBFAE8',
                                              color: '#04401E',
                                              fontWeight: '600',
                                              borderRadius: '10px',
                                            }
                                      }
                                    >
                                      {service.service.enabled === null ? (
                                        <FormattedMessage id="clickToActivate" />
                                      ) : (
                                        <FormattedMessage id="clickToDeactivate" />
                                      )}
                                    </span>
                                  </p>
                                )}
                              </td>
                              <td>
                                {service.price <= 0 ? (
                                  <FormattedMessage id="noPriceFound" />
                                ) : (
                                  <div>{service.price / 100}</div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {onboardingReducer.isOnboardingRequired && (
            <Button variant="secondary" onClick={handleOnboardingNextStep} style={{marginTop: 1 + 'em'}}>
              <FormattedMessage id="nextStepOnboarding" defaultMessage="nextStepOnboarding" />
            </Button>
        )}
      </div>

    </div>
  );
};

export default Services;
