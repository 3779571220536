import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

const AccessDenied = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_LOGOUT_ENDPOINT}`;
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          <FormattedMessage 
            id="accessDenied" 
            defaultMessage="Access Denied"
          />
        </h1>
        <p className="text-xl text-gray-600 mb-8">
          <FormattedMessage 
            id="noAccessToDashboard" 
            defaultMessage="You don't have access to the partner dashboard."
          />
        </p>
        <button
          onClick={handleLogout}
          className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          <FormattedMessage 
            id="logout" 
            defaultMessage="Logout"
          />
        </button>
      </div>
    </div>
  );
};

export default AccessDenied;