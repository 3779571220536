import axios from "../../utils/Api";
import {  SET_ALL_REVIEWS_DATA } from "../constants";

export const getReviews = () => (dispatch:any) => {
    
    axios
        .get("/reviews?limit=5&page=1")
        .then((response:any)=>{
            dispatch({
                type: SET_ALL_REVIEWS_DATA,
                payload: response,
            })
        })
        .catch((error)=>{
            console.log("err",error)
        })    
}

export const deleteReview = () => {}