import { SET_EMPLOYEES_LOADER, SET_INVOICES_LOADER, SET_MONTHLY_LOADER, SET_ORDERS_LOADER, SET_PAYOUTS_LOADER } from "../constants"

// export const setAppSpinner = (val:boolean) => (dispatch : any) => {
//     dispatch({
//         type: SET_APP_SPINNER_LOADER,
//         payload: val
//     })
// }
export const setOrdersLoader = (val:boolean) => (dispatch : any) => {
    dispatch({
        type: SET_ORDERS_LOADER,
        payload: val
    })
}
export const setInvoicesLoader = (val:boolean) => (dispatch : any) => {
    dispatch({
        type: SET_INVOICES_LOADER,
        payload: val
    })
}
export const setEmoloyeesLoader = (val:boolean) => (dispatch : any) => {
    dispatch({
        type: SET_EMPLOYEES_LOADER,
        payload: val
    })
}
export const setMonthlyLoader = (val:boolean) => (dispatch : any) => {
    dispatch({
        type: SET_MONTHLY_LOADER,
        payload: val
    })
}
export const setPayoutsLoader = (val:boolean) => (dispatch : any) => {
    dispatch({
        type: SET_PAYOUTS_LOADER,
        payload: val
    })
}