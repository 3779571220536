import React from 'react'
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from '../store/actions/ReviewsActions';

const Reviews = () => {
  const dispatch :any = useDispatch();
  React.useEffect(()=>{
    dispatch(getReviews())
  },[])
  const reviewsData = useSelector(
    (state: any) => state.reviewsReducer
  );
  console.log("Orders Data",reviewsData)
  return (
    <div>
     <h2><FormattedMessage id='reviews' defaultMessage="Reviews"/></h2>
    </div>
  )
}

export default Reviews