import React from 'react'


export const commentor = [
    {
        id:"public",
        name:"Public"

    },
    {
        id:"service_point",
        name:"Partner"
    },
    {
        id:"private",
        name:"Support"
    }
]

const CommentPrinter = ({ msg, 
//    name
 }: any) => {
  // const commentName = commentor.find((cur) => cur.id === name);
  return <div> {msg} </div>;
};

export default CommentPrinter