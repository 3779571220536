import { useDispatch } from 'react-redux';
import { stripePromise } from '../pages/Invoices';
import { payInvoice } from '../store/actions/InvoicesActions';

const usePayAll = () => {
  const dispatch: any = useDispatch();

  const useAllPayment = () => {
    const body = {
      cancel_url: `${window.location.origin}/invoices/cancel`,
      success_url: `${window.location.origin}/invoices/success`,
    };
    dispatch(
      payInvoice('pay-all', body, (status, res) => {
        if (status) {
          const handleClick = async (checkoutID: any) => {
            const stripe = await stripePromise;

            const result = await stripe?.redirectToCheckout({
              sessionId: checkoutID,
            });

            if (result && result.error && result.error.message) {
              console.log('Payment err res', res);
            }
          };
          handleClick(res.id);
        } else {
          console.log('Payment res', res);
        }
      })
    );
  };
  return { useAllPayment };
};
export default usePayAll;
