import React from 'react';
import tempAxios from 'axios';
import { Form, Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
// import DateFormatter from '../components/subComponents/DateFormatter';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// @ts-ignore
import VideoPlayer from 'react-video-js-player';
import AppSpinner from '../components/subComponents/AppSpinner';
import axios from '../utils/Api';
import { RootState } from '../store';
import {
  getUploadAvatarID,
  getUploadVideoID,
  updateHours,
  updateSettings,
  uploadSettingsAvatar,
  uploadSettingsVideo,
} from '../store/actions/SettingsActions';
import pencil from './assets/pencil.svg';
import Dropzone from '../components/subComponents/Dropzone';
import AlertInfo from '../components/Alerts/AlertInfo';
// import ReactPlayer from 'react-player/youtube';
import AlertCancel from '../components/Alerts/AlertCancel';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import uploadIcon from './assets/upload-icon.png';

const Settings = () => {
  const [apiResp, setApiResp] = React.useState<any>();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading1, setIsLoading1] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [form, setForm] = React.useState({});
  const [showAvatar, setShowAvatar] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const [showUploadError, setShowUploadError] = React.useState(false);
  const [showUplaodSuccess, setShowUploadSuccess] = React.useState(false);
  const [saved, setSaved] = React.useState(true);
  const [openingHour, setOpeningHour] = React.useState<any>({
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
  });
  const [closingHour, setClosingHour] = React.useState<any>({
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
  });
  const [timeError, setTimeError] = React.useState<any>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [uploadIDs, setUploadIDs] = React.useState<any>({
    image: '',
    imageURL: '',
    video: '',
    videoURL: '',
  });
  const [checked, setChecked] = React.useState<any>({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });

  const dispatch: any = useDispatch();
  const initialLoader = useSelector(
    (state: RootState) => state.initialLoaderReducer
  );
  const [video, setVideo] = React.useState<any>();
  const getSettings = () => {
    axios
      .get('/settings')
      .then((response: any) => {
        setApiResp(response.data.data);
        setOpeningHour({
          monday:
            response.data.data.opening_hours.monday &&
            response.data.data.opening_hours.monday.length > 0
              ? response.data.data.opening_hours.monday[0].split('-')[0]
              : null,
          tuesday:
            response.data.data.opening_hours.tuesday &&
            response.data.data.opening_hours.tuesday.length > 0
              ? response.data.data.opening_hours.tuesday[0].split('-')[0]
              : null,
          wednesday:
            response.data.data.opening_hours.wednesday &&
            response.data.data.opening_hours.wednesday.length > 0
              ? response.data.data.opening_hours.wednesday[0].split('-')[0]
              : null,
          thursday:
            response.data.data.opening_hours.thursday &&
            response.data.data.opening_hours.thursday.length > 0
              ? response.data.data.opening_hours.thursday[0].split('-')[0]
              : null,
          friday:
            response.data.data.opening_hours.friday &&
            response.data.data.opening_hours.friday.length > 0
              ? response.data.data.opening_hours.friday[0].split('-')[0]
              : null,
          saturday:
            response.data.data.opening_hours.saturday &&
            response.data.data.opening_hours.saturday.length > 0
              ? response.data.data.opening_hours.saturday[0].split('-')[0]
              : null,
          sunday:
            response.data.data.opening_hours.sunday &&
            response.data.data.opening_hours.sunday.length > 0
              ? response.data.data.opening_hours.sunday[0].split('-')[0]
              : null,
        });

        setClosingHour({
          monday:
            response.data.data.opening_hours.monday &&
            response.data.data.opening_hours.monday.length > 0
              ? response.data.data.opening_hours.monday[0].split('-')[1]
              : null,
          tuesday:
            response.data.data.opening_hours.tuesday &&
            response.data.data.opening_hours.tuesday.length > 0
              ? response.data.data.opening_hours.tuesday[0].split('-')[1]
              : null,
          wednesday:
            response.data.data.opening_hours.wednesday &&
            response.data.data.opening_hours.wednesday.length > 0
              ? response.data.data.opening_hours.wednesday[0].split('-')[1]
              : null,
          thursday:
            response.data.data.opening_hours.thursday &&
            response.data.data.opening_hours.thursday.length > 0
              ? response.data.data.opening_hours.thursday[0].split('-')[1]
              : null,
          friday:
            response.data.data.opening_hours.friday &&
            response.data.data.opening_hours.friday.length > 0
              ? response.data.data.opening_hours.friday[0].split('-')[1]
              : null,
          saturday:
            response.data.data.opening_hours.saturday &&
            response.data.data.opening_hours.saturday.length > 0
              ? response.data.data.opening_hours.saturday[0].split('-')[1]
              : null,
          sunday:
            response.data.data.opening_hours.sunday &&
            response.data.data.opening_hours.sunday.length > 0
              ? response.data.data.opening_hours.sunday[0].split('-')[1]
              : null,
        });
        getVideo();
        setPayload([
          {
            name: 'monday',
            range: [response.data.data.opening_hours.monday[0]],
          },
          {
            name: 'tuesday',
            range: [response.data.data.opening_hours.tuesday[0]],
          },
          {
            name: 'wednesday',
            range: [response.data.data.opening_hours.wednesday[0]],
          },
          {
            name: 'thursday',
            range: [response.data.data.opening_hours.thursday[0]],
          },
          {
            name: 'friday',
            range: [response.data.data.opening_hours.friday[0]],
          },
          {
            name: 'saturday',
            range: [response.data.data.opening_hours.saturday[0]],
          },
          {
            name: 'sunday',
            range: [response.data.data.opening_hours.sunday[0]],
          },

          {
            name: 'exceptions',
            range: {
              '2022-02-10': ['11:00-12:00', '13:00-14:00'],
              '01-01': [],
              '12-25': ['09:00-12:00'],
            },
          },
        ]);
      })
      .catch((err: any) => {
        console.log('error', err);

        //    setIsLoaded(true);
      });
  };
  const getVideo = () => {
    setIsLoaded(true);
    axios.get('/settings/video').then((response: any) => {
      setVideo(response.data);
      console.log('Videoooooooooooooo', response);
    });
  };
  React.useEffect(() => {
    if (initialLoader.loaded) {
      getSettings();
    }
  }, [initialLoader]);
  console.log('api', apiResp);

  function disableClick() {
    setDisabled(!disabled);
  }

  const handleChange = (field: string, value: any) => {
    setForm({ ...form, [field]: value });
  };

  const handleUpdate = () => {
    setSaved(false);
    setShowUploadSuccess(false);
    setShowUploadError(false);
    console.log('form', form);
    console.log('updatedhours', payload);
    if (uploadIDs.image !== '') {
      dispatch(
        uploadSettingsAvatar({ id: uploadIDs.image }, (status: any) => {
          if (status) {
            console.log('image uploaded');
          } else console.log('something went wrong');
        })
      );
    }
    if (uploadIDs.video !== '') {
      dispatch(
        uploadSettingsVideo({ id: uploadIDs.video }, (status: any) => {
          if (status) {
            console.log('video uploaded');
          } else console.log('something went wrong');
        })
      );
    }
    dispatch(
      updateHours({ opening_hours: payload }, (status: boolean) => {
        if (status) {
          console.log('Hours Updated');
        }
      })
    );
    dispatch(
      updateSettings(form, (status: boolean) => {
        if (status) {
          setDisabled(true);
          setSaved(true);
          setShowUploadError(false);
          setShowUploadSuccess(true);
        } else {
          setSaved(true);
          setShowUploadError(true);
          setShowUploadSuccess(false);
        }
      })
    );
  };
  const handleFileChange = (uploadFile: any) => {
    if (!uploadFile) {
      return;
    }
    const file = uploadFile[0];
    dispatch(
      getUploadAvatarID((status: any, response: any) => {
        setIsLoading(true);
        if (status) {
          const { id, upload_url, read_url } = response;
          tempAxios
            .put(upload_url, file, {
              headers: {
                'Content-Type': file.type,
              },
            })
            .then(() => {
              setUploadIDs((prevUploadIDs: any) => ({
                ...prevUploadIDs,
                image: id,
                imageURL: read_url,
              }));
              setIsLoading(false);
            });
        }
      })
    );
  };

  const handleVideoChange = (uploadFile: any) => {
    if (!uploadFile) {
      return;
    }
    const file = uploadFile[0];
    dispatch(
      getUploadVideoID((status: any, response: any) => {
        setIsLoading1(true);
        if (status) {
          const { id, upload_url, read_url } = response;
          tempAxios
            .put(upload_url, file, {
              headers: {
                'Content-Type': file.type,
              },
            })
            .then(() => {
              setUploadIDs((prevUploadIDs: any) => ({
                ...prevUploadIDs,
                video: id,
                videoURL: read_url,
              }));
              setIsLoading1(false);
            });
        }
      })
    );
  };

  console.log('jhhhhhhhhhhhhj', uploadIDs);

  const openAvatarModal = () => {
    setShowAvatar(true);
  };
  const closeAvatarModal = () => {
    setShowAvatar(false);
  };

  const output = (string: string): string[] => {
    const [start, end] = string.split('-');
    const [startHour, startMin] = start
      .split(':')
      .map((num) => parseInt(num, 10));
    const [endHour] = end.split(':').map((num) => parseInt(num, 10));
    const intervals: string[] = [];
    let hour: number = startHour;
    let min: number = startMin;
    while (hour <= endHour) {
      const time: string = `${hour}:${min < 10 ? '0' + min : min}`;
      intervals.push(time);
      min += 15;
      if (min === 60) {
        hour++;
        min = 0;
      }
    }
    return intervals;
  };

  const [payload, setPayload] = React.useState<any>([
    {
      name: 'monday',
      range: [],
    },
    {
      name: 'tuesday',
      range: [],
    },
    {
      name: 'wednesday',
      range: [],
    },
    {
      name: 'thursday',
      range: [],
    },
    {
      name: 'friday',
      range: [],
    },
    {
      name: 'saturday',
      range: [],
    },
    {
      name: 'sunday',
      range: [],
    },

    {
      name: 'exceptions',
      range: {
        '2022-02-10': ['11:00-12:00', '13:00-14:00'],
        '01-01': [],
        '12-25': ['09:00-12:00'],
      },
    },
  ]);

  const handleCheck = (event: any) => {
    const day = event.target.name;
    const isChecked = event.target.checked;
    setChecked((prevChecked: any) => ({ ...prevChecked, [day]: isChecked }));

    setPayload((prevPayload: any) => {
      const updatedPayload = prevPayload.map((item: any) => {
        if (item.name === day) {
          return {
            ...item,
            range: isChecked
              ? [openingHour[day].concat('-', closingHour[day])]
              : [''],
          };
        } else {
          return item;
        }
      });
      console.log('select', updatedPayload);
      return updatedPayload;
    });
  };

  console.log('pay', payload);
  const handleSelect = (event: any) => {
    const day = event.target.id;
    // const field = event.target.name;
    //   const value = event.target.value;

    if (event.target.name === 'open') {
      setOpeningHour((prevState: any) => ({
        ...prevState,
        [day]: event.target.value,
      }));

      //   const timeString = value;
      const timeInMinutes = timeToMinutes(closingHour[day]);
      const openingTimeInMinutes = timeToMinutes(event.target.value);

      if (timeInMinutes <= openingTimeInMinutes) {
        setTimeError((prevState: any) => ({
          ...prevState,
          [day]: true,
        }));
        return;
      } else {
        setTimeError((prevState: any) => ({
          ...prevState,
          [day]: false,
        }));
      }
      setPayload((prevPayload: any) => {
        const updatedPayload = prevPayload.map((item: any) => {
          if (item.name === day) {
            return {
              ...item,
              range: [event.target.value.concat('-', closingHour[day])],
            };
          } else {
            return item;
          }
        });
        console.log(updatedPayload);
        return updatedPayload;
      });
    }

    if (event.target.name === 'close') {
      // const timeString = value;
      const timeInMinutes = timeToMinutes(event.target.value);
      const openingTimeInMinutes = timeToMinutes(openingHour[day]);
      if (timeInMinutes <= openingTimeInMinutes) {
        setTimeError((prevState: any) => ({
          ...prevState,
          [day]: true,
        }));
        return;
      } else {
        setClosingHour((prevState: any) => ({
          ...prevState,
          [day]: event.target.value,
        }));
        setTimeError((prevState: any) => ({
          ...prevState,
          [day]: false,
        }));
      }
      setPayload((prevPayload: any) => {
        const updatedPayload = prevPayload.map((item: any) => {
          if (item.name === day) {
            return {
              ...item,
              range: [openingHour[day].concat('-', event.target.value)],
            };
          } else {
            return item;
          }
        });
        console.log(updatedPayload);
        return updatedPayload;
      });
    }
  };

  function timeToMinutes(time: any) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  return (
    <div className="components-card mb-4">
      {isLoaded ? (
        saved ? (
          <div className="container " style={{ background: 'white' }}>
            <div className="pt-2">
              {showUploadError && (
                <AlertCancel fixed id="settingsUploadError" />
              )}
              {showUplaodSuccess && (
                <AlertSuccess fixed id="settingsUploadSuccess" />
              )}
            </div>
            <Modal show={showAvatar} onHide={closeAvatarModal}>
              <button
                type="button"
                className="close closeButtonModal"
                aria-label="Close"
                onClick={closeAvatarModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="p-2">
                <div
                  style={{
                    background: disabled
                      ? `url(${apiResp.avatar})`
                      : `url(${uploadIDs.imageURL})`,
                    // minHeight: '120px',
                    height: '500px',
                    //  width: '620px',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </div>
            </Modal>
            <Modal show={showVideo} onHide={() => setShowVideo(false)}>
              <button
                type="button"
                className="close closeButtonModal"
                aria-label="Close"
                onClick={() => setShowVideo(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div style={{ padding: '2rem' }}>
                <div
                // style={{
                //   //minHeight: '120px',
                //   height: '500px',
                //   //  width: '620px',

                // }}
                >
                  <VideoPlayer width="500" height="500" src={video} controls />
                </div>
              </div>
            </Modal>

            <div className="pt-3 p-3">
              <div className="d-flex justify-content-between">
                <div
                  className="heading-media order-media"
                  style={{ marginBottom: '20px' }}
                >
                  <div className="heading mb-0">
                    <h2>
                      <FormattedMessage id="settings" />
                    </h2>
                  </div>
                </div>
                <div style={{ textAlign: 'right' }}>
                  {disabled === true ? (
                    <button
                      type="submit"
                      onClick={disableClick}
                      className="Box-type-medium d-flex"
                      style={{ gap: '12px' }}
                    >
                      <img src={pencil} className="img-fluid" alt="edit" />{' '}
                      <p style={{ lineHeight: '17px' }}>
                        {' '}
                        <FormattedMessage id="settings.edit" />
                      </p>
                    </button>
                  ) : (
                    [
                      <button
                        type="submit"
                        onClick={disableClick}
                        className="btn btn-primary mx-2 d-none d-sm-inline"
                      >
                        <FormattedMessage id="settings.back" />
                      </button>,
                      <button
                        type="submit"
                        onClick={handleUpdate}
                        className="btn btn-success mx-2 d-none d-sm-inline"
                      >
                        <FormattedMessage id="settings.update" />
                      </button>,
                    ]
                  )}
                </div>
              </div>
              <div className="row mb-4">
                {disabled === false && (
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <p className="settingsSubheading mb-1">
                      <FormattedMessage id="settings.photo" />
                    </p>
                    <div className="d-flex settingsMediaBox justify-content-between">
                      <div className="img-container">
                        <div
                          style={{
                            height: '164px',
                            width: '200px',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          {isLoading ? (
                            <Spinner
                              animation="border"
                              role="status"
                              variant="primary"
                            />
                          ) : uploadIDs.imageURL ? (
                            <img
                              src={uploadIDs.imageURL}
                              style={{ width: '200px', height: '150px' }}
                              alt="Uploding"
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        {uploadIDs.imageURL ? (
                          <div className="middle">
                            <div
                              role="button"
                              tabIndex={0}
                              className="img-container-text"
                              onClick={openAvatarModal}
                            >
                              <i
                                className="fa fa-search-plus"
                                aria-hidden="true"
                                style={{
                                  position: 'absolute',
                                  left: '45%',
                                  top: '45%',
                                }}
                              />
                              <p
                                style={{
                                  position: 'absolute',
                                  left: '30%',
                                  top: '62%',
                                  opacity: '1',
                                }}
                              >
                                <FormattedMessage id="settings.viewPhoto" />
                              </p>
                            </div>
                          </div>
                        ) : undefined}
                      </div>

                      <div style={{ alignSelf: 'center', width: '60%' }}>
                        {' '}
                        <Dropzone handleDrop={handleFileChange} />
                      </div>
                    </div>
                  </div>
                )}

                {disabled === false && (
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <p className="settingsSubheading mb-1">Video:</p>
                    <div className="d-flex settingsMediaBox justify-content-between">
                      <div className="img-container">
                        <div
                          style={{
                            height: '164px',
                            width: '200px',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          {isLoading1 ? (
                            <Spinner
                              animation="border"
                              role="status"
                              variant="primary"
                            />
                          ) : uploadIDs.videoURL ? (
                            <video controls muted width="200">
                              <source
                                src={uploadIDs.videoURL}
                                type="video/mp4"
                              />
                              <track
                                kind="captions"
                                srcLang="en"
                                label="English"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div style={{ alignSelf: 'center', width: '60%' }}>
                        {' '}
                        <Dropzone handleDrop={handleVideoChange} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row mb-4">
                {disabled === true && (
                  <div className="col-lg-3 col-md-9 col-sm-6">
                    <p className="settingsSubheading mb-1">
                      <FormattedMessage id="settings.photo" />:
                    </p>
                    <div
                      style={{ border: 'none' }}
                      className="d-flex settingsMediaBox {} justify-content-between"
                    >
                      <div className="img-container">
                        <div
                          style={{
                            background: apiResp.avatar
                              ? `url(${apiResp.avatar})`
                              : uploadIcon,
                            height: '164px',
                            width: '200px',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                        />
                        {apiResp.avatar ? (
                          <div className="middle">
                            <div
                              role="button"
                              tabIndex={0}
                              className="img-container-text"
                              onClick={openAvatarModal}
                            >
                              <i
                                className="fa fa-search-plus"
                                aria-hidden="true"
                                style={{
                                  position: 'absolute',
                                  left: '45%',
                                  top: '45%',
                                }}
                              />
                              <p
                                style={{
                                  position: 'absolute',
                                  left: '30%',
                                  top: '62%',
                                  opacity: '1',
                                }}
                              >
                                <FormattedMessage id="settings.viewPhoto" />
                              </p>
                            </div>
                          </div>
                        ) : undefined}
                      </div>
                    </div>
                  </div>
                )}

                {disabled === true && (
                  <div className="col-lg-3 col-md-9 col-sm-6">
                    <p className="settingsSubheading mb-1">
                      <FormattedMessage id="settings.video" />:
                    </p>
                    <div
                      style={{ border: 'none' }}
                      className="d-flex settingsMediaBox justify-content-between"
                    >
                      <div className="img-container">
                        <div
                          style={{
                            backgroundColor: apiResp.video
                              ? 'black'
                              : 'transparent',
                            background: apiResp.video ? '' : uploadIcon,
                            height: '164px',
                            width: '200px',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                        />
                        {apiResp.video ? (
                          <div style={{ opacity: '1' }} className="middle">
                            <div
                              role="button"
                              tabIndex={0}
                              className="img-container-text"
                              onClick={() => setShowVideo(true)}
                            >
                              <i
                                className="fa fa-play-circle"
                                aria-hidden="true"
                                style={{
                                  position: 'absolute',
                                  left: '40%',
                                  top: '35%',
                                  fontSize: '55px',
                                  color: 'white',
                                }}
                              />
                            </div>
                          </div>
                        ) : undefined}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-2 row settingsSubTextColor">
                <label className="col-lg-1 col-sm-2 col-form-label">Name</label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    defaultValue={apiResp.name}
                    onChange={(e) => {
                      handleChange('name', e.target.value);
                    }}
                    // placeholder={apiResp.name}
                  />
                </div>
                <label
                  htmlFor="Email"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.email" />
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    id="Email"
                    type="email"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('email', e.target.value);
                    }}
                    disabled={disabled}
                    defaultValue={apiResp.email}
                    // placeholder={apiResp.name}
                  />
                </div>
              </div>
              <div className="mb-2 row settingsSubTextColor">
                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.street" />
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('street', e.target.value);
                    }}
                    disabled
                    defaultValue={apiResp.street}
                    // placeholder={apiResp.name}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.street" /> #
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('street_number', e.target.value);
                    }}
                    disabled
                    defaultValue={apiResp.street_number}
                    // placeholder={apiResp.name}
                  />
                </div>
              </div>
              <div className="mb-2 row settingsSubTextColor">
                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.city" />
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('city', e.target.value);
                    }}
                    disabled
                    defaultValue={apiResp.city}
                    // placeholder={apiResp.name}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.phone" />
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <PhoneInput
                    style={
                      disabled ? { backgroundColor: '#E9ECEF' } : undefined
                    }
                    className="form-control PhoneInputInput input-phone-number"
                    defaultCountry="NL"
                    value={apiResp.phone}
                    disabled={disabled}
                    defaultValue={apiResp.phone}
                    placeholder={apiResp.phone}
                    onChange={(value) => handleChange('phone', value)}
                  />
                </div>
              </div>
              {/* <div className="mb-2 row settingsSubTextColor">
                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  Public URL
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('public_url', e.target.value);
                    }}
                    disabled={disabled}
                    defaultValue={apiResp.public_url}
                    placeholder={apiResp.name}
                  />
                </div>
                <label
                htmlFor="staticEmail"
                className="col-lg-1 col-sm-2 col-form-label"
              >
                Department
              </label>
              <div className="col-lg-5 col-sm-10 my-1">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    handleChange('department', e.target.value);
                  }}
                  disabled={disabled}
                  defaultValue={apiResp.department}
                  // placeholder={apiResp.name}
                />
              </div>
              </div> */}
              <div className="mb-2 row settingsSubTextColor">
                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.coc" />
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('coc', e.target.value);
                    }}
                    disabled
                    defaultValue={apiResp.coc}
                    // placeholder={apiResp.name}
                  />
                </div>

                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.phone" /> 2
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <PhoneInput
                    style={
                      disabled ? { backgroundColor: '#E9ECEF' } : undefined
                    }
                    className="form-control PhoneInputInput input-phone-number"
                    // defaultCountry="NL"
                    // value={value}
                    disabled={disabled}
                    defaultValue={apiResp.phone_2}
                    placeholder={apiResp.phone_2}
                    onChange={(value) => handleChange('phone_2', value)}
                  />
                  {/* <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    handleChange('phone_2', e.target.value);
                  }}
                  disabled={disabled}
                  defaultValue={apiResp.phone_2}
                  // placeholder={apiResp.name} 
                /> */}
                </div>
              </div>
              <div className="mb-2 row settingsSubTextColor">
                <label className="col-lg-1 col-sm-2 col-form-label">
                  <FormattedMessage id="settings.zipcode" />
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('zip_code', e.target.value);
                    }}
                    disabled
                    defaultValue={apiResp.zip_code}
                    // placeholder={apiResp.name}
                  />
                </div>
                <label
                  htmlFor="staticEmail"
                  className="col-lg-1 col-sm-2 col-form-label"
                >
                  <FormattedMessage id="settings.website" />
                </label>
                <div className="col-lg-5 col-sm-10 my-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      handleChange('website', e.target.value);
                    }}
                    disabled={disabled}
                    defaultValue={apiResp.website}
                    // placeholder={apiResp.name}
                  />
                </div>
              </div>
            </div>
            {apiResp?.agent && (
              <div className="settingsSubTextColor">
                <div
                  className="heading-media order-media"
                  style={{ marginBottom: '20px' }}
                >
                  <div className="heading mb-0">
                    <h2>
                      {/* <FormattedMessage id="subscritptionPerformance" /> */}
                      Subscription Performance
                    </h2>
                  </div>
                </div>
                <div>
                  <div className="heading mb-0">
                    <h2>
                      {/* <FormattedMessage id="subscritptionPerformance" /> */}
                      Subscription Performance
                    </h2>
                  </div>
                  <p className="lh-base">
                    <strong>Agent Name: </strong>{' '}
                    {apiResp.subscription_performance?.agent.first_name}{' '}
                    {apiResp.subscription_performance?.agent.last_name}
                  </p>
                  <p className="lh-base">
                    <strong>Agent Email: </strong>{' '}
                    {apiResp.subscription_performance?.agent.email}
                  </p>
                  <p className="lh-base">
                    <strong>Agent Phone: </strong>{' '}
                    {apiResp.subscription_performance?.agent.phone}
                  </p>
                </div>
              </div>
            )}

            {/* <div className="row col-lg-6 col-md-12 col-sm-12" style={{paddingLeft:"1%"}}>
            <AlertInfo id="settingsUpdateFixedText"/>
          </div> */}
            {/* <div className="row p-3 settingsSubTextColor">
            {!disabled && (
              <div className="col-lg-6 col-sm-12 ">
                <div>
                  <AlertInfo id="settingsUpdateFixedText" />
                </div>
              </div>
            )}
            </div> */}
            <div className="row p-3 settingsSubTextColor">
              <div className="col-lg-6 col-sm-12 ">
                {!disabled && (
                  <div className="row">
                    <div>
                      <AlertInfo id="settingsUpdateFixedText" />
                    </div>
                  </div>
                )}
                <div className="row" style={{ padding: 'inherit' }}>
                  <FormattedMessage id="settings.desc" />
                  <Form.Control
                    as="textarea"
                    rows={7}
                    placeholder="Description"
                    aria-label="Username"
                    className="mt-4 mb-2"
                    disabled={disabled}
                    defaultValue={apiResp.description}
                    onChange={(e) => {
                      handleChange('description', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: disabled === true ? '#EBEDEE' : undefined,
                  pointerEvents: disabled === true ? 'none' : undefined,
                }}
                className="d-none d-sm-block col-lg-6 col-sm-12 settingsMediaBox p-3"
              >
                {disabled === false ? (
                  <FormattedMessage id="settings.seleTime" />
                ) : (
                  ''
                )}
                <table className=" table text-center table-responsive mt-0  settingsSubTextColor">
                  <thead>
                    <tr>
                      <th> </th>
                      <th scope="col">
                        <FormattedMessage id="settings.days" />
                      </th>
                      <th scope="col">
                        <FormattedMessage id="settings.oHours" />
                      </th>
                      <th scope="col">
                        <FormattedMessage id="settings.cHours" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(apiResp.opening_hours).map(
                      (obj: any, index: number) => {
                        const s = apiResp.opening_hours[obj];
                        const hours = s[0]?.split('-');
                        return (
                          hours && (
                            <tr
                              className={
                                index + 1 ===
                                Object.keys(apiResp.opening_hours).length - 1
                                  ? ''
                                  : 'lineTable'
                              }
                            >
                              <th scope="row">
                                {' '}
                                <input
                                  id="mainCheckBox"
                                  className="StateChecked "
                                  type="checkbox"
                                  checked={checked[obj]}
                                  value=""
                                  name={obj}
                                  onChange={handleCheck}
                                  //   onClick={handleCheckAll}
                                  style={{
                                    backgroundColor:
                                      disabled === true ? '#EBEDEE' : undefined,
                                    border:
                                      disabled === true ? 'none' : undefined,
                                  }}
                                />
                              </th>
                              <td>{obj}</td>
                              <td className="d-flex justify-content-center">
                                <div style={{ width: '49%' }}>
                                  <select
                                    id={obj}
                                    name="open"
                                    key={obj}
                                    className="form-select form-select-sm"
                                    disabled={!checked[obj]}
                                    onChange={handleSelect}
                                    style={{
                                      backgroundColor:
                                        disabled === true
                                          ? '#EBEDEE'
                                          : undefined,
                                      border:
                                        disabled === true ? 'none' : undefined,
                                      width: '82px',
                                    }}
                                  >
                                    {output(apiResp.opening_hours[obj][0]).map(
                                      (time) => (
                                        <option key={time} value={time}>
                                          {time}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </td>
                              <td>
                                <div style={{ width: '49%' }}>
                                  <select
                                    id={obj}
                                    name="close"
                                    disabled={!checked[obj]}
                                    className="form-select form-select-sm"
                                    onChange={handleSelect}
                                    defaultValue={
                                      output(apiResp.opening_hours[obj][0])[
                                        output(apiResp.opening_hours[obj][0])
                                          .length - 1
                                      ]
                                    }
                                    style={{
                                      backgroundColor:
                                        disabled === true
                                          ? '#EBEDEE'
                                          : undefined,
                                      border:
                                        disabled === true ? 'none' : undefined,
                                      WebkitAppearance:
                                        disabled === true ? 'none' : undefined,
                                      width: '82px',
                                    }}
                                  >
                                    {output(apiResp.opening_hours[obj][0]).map(
                                      (time) => (
                                        <option key={time} value={time}>
                                          {time}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </td>
                            </tr>
                          )
                        );
                      }
                    )}
                  </tbody>
                </table>
                {Object.values(timeError).some((error) => error) ? (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    * Closing Time Should Not Be Less Than Opening Time
                  </p>
                ) : (
                  ''
                )}
              </div>
              {disabled === false ? (
                <p className="mb-2 mt-2 d-block d-sm-none">
                  Select your availabilty
                </p>
              ) : (
                <p className="mb-2 mt-2 d-block d-sm-none">Availability</p>
              )}
              <div
                style={{
                  backgroundColor: disabled === true ? '#EBEDEE' : undefined,
                  pointerEvents: disabled === true ? 'none' : undefined,
                }}
                className="d-block d-sm-none col-lg-6 col-sm-12 settingsMediaBox p-3"
              >
                {Object.keys(apiResp.opening_hours).map(
                  (obj: any, index: number) => {
                    const s = apiResp.opening_hours[obj];
                    const hours = s[0]?.split('-');
                    return (
                      hours && (
                        <div
                          className={
                            index + 1 ===
                            Object.keys(apiResp.opening_hours).length - 1
                              ? ''
                              : 'lineTable'
                          }
                        >
                          <div
                            className="row mt-4 mb-4"
                            style={{ textAlign: 'right' }}
                          >
                            <div className="col-3">
                              <input
                                id="mainCheckBox"
                                className="StateChecked "
                                type="checkbox"
                                value=""
                                checked={checked[obj]}
                                name={obj}
                                onChange={handleCheck}
                                // onClick={handleCheckAll}
                                style={{
                                  backgroundColor:
                                    disabled === true ? '#EBEDEE' : undefined,
                                  border:
                                    disabled === true ? 'none' : undefined,
                                  marginTop: '45px',
                                }}
                              />
                            </div>
                            <div
                              className="col-9"
                              style={{ textAlign: 'left' }}
                            >
                              <div className="row">
                                <div className="col mb-4 mx-4">
                                  <label>Day:</label>
                                  <span>
                                    {'  '}
                                    {obj}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col mb-4">
                                  <label htmlFor="open">
                                    <FormattedMessage id="settings.oHours" />:
                                  </label>
                                  {'  '}
                                  <select
                                    id={obj}
                                    name="open"
                                    key={obj}
                                    className="form-select form-select-sm"
                                    disabled={!checked[obj]}
                                    onChange={handleSelect}
                                    style={{
                                      backgroundColor:
                                        disabled === true
                                          ? '#EBEDEE'
                                          : undefined,
                                      border:
                                        disabled === true ? 'none' : undefined,
                                      width: '82px',
                                      display: 'inline',
                                    }}
                                  >
                                    {output(apiResp.opening_hours[obj][0]).map(
                                      (time) => (
                                        <option key={time} value={time}>
                                          {time}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <label>
                                    <FormattedMessage id="settings.cHours" />:
                                  </label>
                                  {'  '}
                                  <select
                                    id={obj}
                                    name="close"
                                    className="form-select form-select-sm"
                                    onChange={handleSelect}
                                    disabled={!checked[obj]}
                                    defaultValue={
                                      output(apiResp.opening_hours[obj][0])[
                                        output(apiResp.opening_hours[obj][0])
                                          .length - 1
                                      ]
                                    }
                                    style={{
                                      backgroundColor:
                                        disabled === true
                                          ? '#EBEDEE'
                                          : undefined,
                                      border:
                                        disabled === true ? 'none' : undefined,
                                      WebkitAppearance:
                                        disabled === true ? 'none' : undefined,
                                      width: '82px',
                                      display: 'inline',
                                    }}
                                  >
                                    {output(apiResp.opening_hours[obj][0]).map(
                                      (time) => (
                                        <option key={time} value={time}>
                                          {time}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  }
                )}
                {Object.values(timeError).some((error) => error) ? (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    * Closing Time Should Not Be Less Than Opening Time
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            {disabled === false ? (
              <div
                className="d-block d-sm-none"
                style={{ textAlign: 'center' }}
              >
                <button
                  type="submit"
                  onClick={handleUpdate}
                  className="Box-type-medium mx-2"
                  style={{ display: 'inline' }}
                >
                  Save Changes
                </button>
                <button
                  type="submit"
                  onClick={disableClick}
                  className="Box-type-medium mx-2"
                  style={{
                    color: '#3482BA',
                    display: 'inline',
                    backgroundColor: '#efefef',
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : undefined}
            <input className="d-none" type="file" />
          </div>
        ) : (
          <AppSpinner message="savingInProgress" />
        )
      ) : (
        <AppSpinner />
      )}
    </div>
  );
};

export default Settings;
