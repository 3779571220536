import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import TaskTicket from '../components/Dashboard/TaskTicket';
import usePayAll from '../customHooks/usePayAll';
import { RootState } from '../store';
import ModalDebit from '../components/subComponents/ModalDebit';
import axios from '../utils/Api';

const Tasks = () => {
  const invoiceData = useSelector((state: RootState) => state.invoiceReducer);
  const ordersData = useSelector((state: RootState) => state.orderReducer);
  const appLoaded = useSelector((state:RootState)=>state.appSpinnerReducer);
  const userReducer = useSelector((state:RootState)=>state.userReducer);
  const [showExportModal, setExportShowModal] = React.useState(false);
  const [error, setError] = React.useState<boolean>();
  const closeModal = () => setExportShowModal(false);
  const [taskList, setTaskList] = React.useState<any>([]);

  const {useAllPayment} = usePayAll()
  const navigate:any = useNavigate();
   const Redriector = () => {
   navigate("/orders?statuses[]=awaiting_completion")
    } 
    const handleDebit = () => {
        axios.put('/settings/request-direct-debit')
        .then((response: any) => {
          console.log('res' , response)
          setError(false);
        })
        .catch((err: any) => {
          console.log('error' , err)
          setError(true);
        })
        .finally(() => {
          setExportShowModal(true);
        });
    };
  React.useEffect(() => {
    const tempTaskObjArr = [];
    const tempTaskObj = {
      check: invoiceData.meta.payable_invoices?.total === 0,
      action: useAllPayment,
      actionLabel: 'payAllInvoices',
      translatedActionLabel:"invoicesTaskSuccess",
      buttonLabel:"payAll",
      required: invoiceData.meta.payable_invoices?.total !== 0,
      icon: '💵',
      priority: 1,
      isLoaded:appLoaded.loaded.invoices,
      loadedText:"taskLoadingMessage"
    };
    tempTaskObjArr.push(tempTaskObj);
    const tempTaskObj2 = {
      check: ordersData.meta?.statuses?.awaiting_completion === 0,
      action: Redriector,
      actionLabel: 'finishOrdersTaskText',
      translatedActionLabel:"ordersTaskSuccess",
      buttonLabel:"finishOrders",
      required: true,
      icon: '🧾',
      priority: 8,
      isLoaded:appLoaded.loaded.orders,
      loadedText:"taskLoadingMessage"
    };
    tempTaskObjArr.push(tempTaskObj2);

    // const tempTaskObj3 = {
    //   check: userReducer.debitStatus === 'activated',
    //   action: handleDebit,
    //   actionLabel: 'debitText',
    //   translatedActionLabel:"debitTextCompleted",
    //   buttonLabel:"debitButtonText",
    //   icon: <i className="fa fa-repeat" aria-hidden="true"/>,
    //   priority: 8,
    //   statusDisabled: userReducer.debitStatus !== 'disabled',
    //   statusPending: userReducer.debitStatus === 'pending',
    //   isLoaded:appLoaded.loaded.orders,
    //   loadedText:"taskLoadingMessage"
    // };
    // tempTaskObjArr.push(tempTaskObj3);

    const sortedTasks = tempTaskObjArr.sort((a, b) =>
      a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0
    );

    setTaskList(sortedTasks);
  }, [invoiceData,ordersData,appLoaded]);

  return (
    <div className="dashboard-box">
      <ModalDebit
        show={showExportModal}
        handleClose={closeModal}
        title="debitTextModal"
        text="debitTextModal"
        showError={error}
        okButtonText="debitButtonText"
        // okButtonAction={handleUpdate}
        cancelButtonText="Back"
        cancelButtonAction={closeModal}
      /> 
      <div className="heading">
        <h2>
          <FormattedMessage id="tasks" />
        </h2>
      </div>
      {taskList.map((task: any) => {
        return (
          <div key={task.actionLabel}>
            <TaskTicket
              check={task.check}
              action={task.action}
              actionLabel={task.actionLabel}
              icon={task.icon}
              required={task.required}
              statusDisabled={task.statusDisabled}
              statusPending={task.statusPending}
              translatedActionLabel={task.translatedActionLabel}
              buttonLabel={task.buttonLabel}
              isLoaded={task.isLoaded}
              loadedText={task.loadedText}
            />
          </div>
        );
      })}
      {/* <div className="text-end mt-3">
      <Link className="Some-link" to="tasks">
        <FormattedMessage id="viewAll"/> <img src="images/sm_arrow-right.svg" alt="" />
      </Link>
    </div> */}
    </div>
  );
};

export default Tasks;
