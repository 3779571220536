export const statusCards = [
    {
      id: 'paid',
      color: 'positive',
      class: 'Positive-status',
    },
    {
      id: 'open',
      color: 'info',
      class: 'info-status',
    },
    {
      id: 'late',
      color: 'negative',
      class: 'Negative-status',
    },
    {
      id: 'reminded',
      color: 'warning',
      class: 'Warning-status',
    },
    {
      id: 'uncollectible',
      color: 'negative',
      class: 'Negative-status',
    },
    {
      id: 'processing',
      color: 'info',
      class: 'info-status',
    },
    {
      id: 'scheduled',
      color: 'neutral',
      class: 'Neutral-status',
    },
    {
      id: 'awaiting_completion',
      color: 'warning',
      class: 'Warning-status',
    },
    {
      id: 'completed',
      color: 'positive',
      class: 'Positive-status',
    },
    {
      id: 'cancelled',
      color: 'negative',
      class: 'Negative-status',
    },
    {
      id: 'pending_payment',
      color: 'warning',
      class: 'Warning-status',
    },
    {
      id: 'draft',
      color: 'neutral',
      class: 'Neutral-status',
    },
    {
      id: 'missed_orders',
      color: 'neutral',
      class: 'Neutral-status',
    },
    {
      id: 'draftPayouts',
      color: 'neutral',
      class: 'Neutral-status',
    }
  ];