import React from 'react';
import { FormattedMessage } from 'react-intl';
import AlertCancel from '../components/Alerts/AlertCancel';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import { callExternalApi } from '../services/api-call';
import { stripePromise } from './Invoices';

const PublicPayoutPage = () => {
  const [showMsg, setShowMsg] = React.useState(0);
  const [errMsg,setErrMsg] = React.useState("");
  const [disabled,setDisabled] = React.useState(false);
  const [id, setId] = React.useState<any>();
  const payAllPublicly = async (sp: any) => {
    localStorage.setItem('srvrght', sp);
    setDisabled(true)
    const config = {
      url: `${process.env.REACT_APP_PARTNER_API_ENDPOINT}/invoices/payall/${sp}?secret_hash=null`,
      method: 'PUT',
      data: {
        success_url:`${process.env.REACT_APP_URL}/invoices/payall/success`,
        cancel_url: `${process.env.REACT_APP_URL}/invoices/payall/cancel`
      },
    };

    const { data, error } = await callExternalApi({ config });
    console.log('error', error);
    if (!error) {
      const handleClick = async (checkoutID: any) => {
        const stripe = await stripePromise;
        const result = await stripe?.redirectToCheckout({
          sessionId: checkoutID,
        });
        if (result && result.error && result.error.message) {
          console.log('Payment err res', error);
        }
      };
      handleClick(data.id);
    } else {
      console.log('eer', error);
      setErrMsg(error.message)
      setDisabled(false)
    }
  };

  React.useEffect(() => {
    if (window.location.href.includes('success')) {
      setShowMsg(1);
      localStorage.removeItem('srvrght');
    }
    else if (window.location.href.includes('cancel')) 
         setShowMsg(2);
    else {
      if (localStorage.getItem('srvrght'))
       localStorage.removeItem('srvrght');
      setShowMsg(0);
    }

    if (localStorage.getItem('srvrght')) {
      setId(localStorage.getItem('srvrght'));
    } else {
     // const pathname = window.location.pathname;
      const sp = window.location.pathname.split("/invoices/payall/")
      if (sp.length!==0&&errMsg==="") payAllPublicly(sp[1]);
    }
  },[]);
  return (
    <div className="components-card">
      <div className="container">
        {(showMsg === 0&&errMsg==="") && (
          <h4>
            <FormattedMessage id="publicInvoiceMessage" />
          </h4>
        )}
        {showMsg === 1 && <AlertSuccess id="successPaymentMessage" fixed />}
        {showMsg === 2 && (
          <AlertCancel
            id="cancelPaymentMessage"
            showButton 
            action={() => {
              payAllPublicly(id);
            }}
            actionLabel="retryPayment"
            isDisabled={disabled}
          />
        )}
        {errMsg!=="" && <h2><strong>{errMsg}</strong></h2>}
      </div>
    </div>
  );
};

export default PublicPayoutPage;
