import React from 'react';
import { FormattedMessage } from 'react-intl';

const DashboardComponentContainer = ({ children }: any) => {
  return (
    <div>
      {' '}
      <div className="heading">
        <h2>
          <FormattedMessage id="invoices" defaultMessage="Invoices" />
        </h2>
      </div>
      <div className="invoice-table">
        <div className="table-responsive">
          <table className="table-striped">
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardComponentContainer;
