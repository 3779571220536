import React from 'react';

const DifferenceCalculator = ({
  finalValue = 0,
  previousValue = 0,
  inverseColor = false,
}: any) => {
  const [positive, setPositive] = React.useState(true);
  const [isNeutral, setIsNeutral] = React.useState(true);
  const difference = finalValue - previousValue 
  // console.log("per",difference,finalValue,previousValue)
  React.useEffect(() => {
    if (finalValue === previousValue) setIsNeutral(true);
    // else if (previousValue === 0) setIsNeutral(true);
    else setIsNeutral(false);

    if (difference < 0) setPositive(false);
  }, []);
  return (
    <div>
      {!isNeutral ? (
        <p
          className={
            positive && !inverseColor
              ? 'ComparisonDown---Positive'
              : 'ComparisonDown---Negative'
          }
        >
          {' '}
          <span>
            {positive ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.333 9.333V4.667H6.667M4.667 11.333l6.666-6.666"
                  stroke={inverseColor ? '#AE1313' : '#08873f'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.667 11.333h4.666V6.667M4.667 4.667l6.666 6.666"
                  stroke="#AE1313"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
          {Math.round(Math.abs(difference))}
        </p>
      ) : (
        <p className="ComparisonDown---Neutral">&nbsp; 0</p>
      )}
    </div>
  );
};

export default DifferenceCalculator;
